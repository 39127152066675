.news-widget {
  .list-group {
    margin: 5px -1px -1px;
    .news-item {
      margin-top: -1px;
      .clearfix;
      .news-title {
        .text-truncate();
        display: block;
      }
      &:hover, &:focus {
        .news-title {
          color: @link-hover-color;
          text-decoration: underline;
        }
      }
      .news-summary {
        .text-multiline-truncate(2);
      }
      .news-published {
        display: block;
        font-weight: bold;
        font-size: @font-size-small;
        margin-top: 5px;
        float: right;
        line-height: 1;
      }
      &:before {
        display: none;
      }
      @media (min-width: @screen-sm) {
        padding: 7.13px @grid-padding;
        .double-height & {
          padding: 8.5px @grid-padding;
        }
        &.news-item-detailed {
          padding: 10px @grid-padding;
          max-width: none;
          float: none;
          height: 108px;
          overflow: hidden;
        }
      }
    }
  }
}