@import "bootstrap/less/type.less";

// ----------------------------------------------------------------------------
// Type
// ----------------------------------------------------------------------------

/* Headings & Typography
-------------------------------------------------------------- */
body {
  font-weight: 400;
}
h1, h2, h3, h4, h5, h6 {
  font-family: @headings-font-family;
  font-weight: 700;
  font-style: italic;
  .type-margin(0, 20);
  color: @gray-darker;
  a:hover &, a:focus & {
    text-decoration: underline;
    color: @link-hover-color; 
  }
}


h1 {
  .type-size(@font-size-h1);
  .type-margin(0, 20);
  .heading-font();
  line-height: 1.434em;
}
h2 {
  .type-size(@font-size-h2);
  .type-margin(0, 15);
  .heading-font();
  line-height: 1.076em;
}
h3 {
  .type-size(@font-size-h3);
  .type-margin(20, 10);
  font-weight: 500;
}
h4 {
  .type-size(@font-size-h4);
  .type-margin(15, 5);
  font-weight: 500;
}
h5 {
  .type-size(@font-size-h5);
  font-weight: 700;
}
h6 {
  .type-size(@font-size-h6);
  text-transform: uppercase;
}

.well {
  h2, h3 {
    .type-size(@font-size-h2);
  }
  h2 ~ h3 {
    .type-size(@font-size-h3);
  }
}

.layout-compact {
  h1 { 
    margin-top: 1.5rem;
    font-size: ( @font-size-h1 * 0.8 ) 
  }
  h2 { 
    margin-top: 1.5rem;
    font-size: ( @font-size-h2 * 0.8 ) 
  }
  h3 { 
    margin-top: 1.5rem;
    font-size: ( @font-size-h3 * 0.8 ) 
  }
}

h2 + h3, h2 + code + h3 {
  .type-margin(0, 10);
}

.header-prepend, .header-append {
  .type-size(@font-size-h6);
  font-weight: 400;  display: block;
  line-height: 1.076em;
}
.header-prepend {
  margin-top: @unit;
  &+h1, &+h2, &+h3 {
    margin-bottom: 0;
  }
  .orderid, .orderdate {
    display: inline-block;
    padding-right: @unit;
    i {
      padding-right: 2px;
    }
  }
}
.header-append {
  margin-top: -@unit;
  margin-bottom: @unit;
}



.text-lg {font-size: 18px;}
.text-left {text-align: left !important;}
.text-right {text-align: right !important;}
.text-center {text-align: center !important;}

p {
  .type-margin(0, 10);
  line-height: @line-height-base;
}

.intro {
  font-size: @font-size-large;
  margin-bottom: 1.5em
}



.inner-ellipsis {
  opacity: .6;
}

blockquote {
  .type-size(@font-size-large);
  color: @gray-darker;
  line-height: 147%;
  font-weight: 200;
  padding: 0;
  margin: 0 0 15px 0;
  padding-right: @grid-gutter-width;
  border:none;
  margin: @dub-unit 0;
  .make-md-column-offset(1);
  &:before {
    content: '”';
    font-family: @font-family-serif;
    font-size: 70px;
    color: @gray-dark;
    position: relative;
    top: 30px;
    left: -5px;
  }
  &:after {
    content: '”';
  }
}

.lead {
  .type-size(@font-size-large);
  line-height: 1.1666667em;
  .type-margin(0, 35);
  font-family: @font-family-base;
  color: @gray-darker;
}

code {
  padding: @half-unit;
  margin-bottom: 3px;
  white-space: normal;
  overflow: hidden;
  .type-size(@font-size-small);
  .meta-comment & {
    line-height: 13px;
  }
  &.block {
    display: block;
  }
  pre & {
    white-space: pre-wrap;
  }
}

dl {
  dt {
    margin-top: 1em;
  }
}

//Abbr
abbr[title] {
  cursor: inherit;
  border-bottom: none;
}

//Alert colors
.text-default {
  color: @text-color !important;;
}
.text-danger {
  color: @brand-danger !important;
}
.text-info {
  color: @brand-info !important;;
}
.text-success {
  color: @brand-success !important;;
}
.text-warning {
  color: @brand-warning !important;;
}
.text-primary {
  color: @brand-primary !important;;
}
.text-disabled {
  color: @gray-light !important;;
}


.text-available {
 color: darken(@brand-success, 5%);
}
.text-unavailable {
 color: darken(@brand-danger, 5%);
}
.text-error {
 color: gray;
}
   

.text-center {
 text-align: center !important;
}
.text-left {
 text-align: left !important;
}
.text-right {
 text-align: right !important;
}

.heading-font {
  font-family: @headings-font-family;
  font-size-adjust:0.47;
  font-weight: 700;
  font-style:italic;
  -webkit-font-smoothing: antialiased;
}

.status-font-style {
  font-weight: 200;
  font-size: @font-size-large;
  -webkit-font-smoothing: antialiased;
}