.jBox-Notice {
	.jBox-container {
		box-shadow: 0 2px 3px rgba(0, 0, 0, .2) !important;
		color: #444 !important;
		text-shadow: none !important;
		background: #fff !important;
		background-image: none !important;
		
		.jBox-content {
			word-break: normal;
			white-space: nowrap;
		}
	}
	
	.jBox-pointer-top:after {
		box-shadow: none !important;
	}
}