nav.secondary-menu {
  padding: 0;
  list-style: none;
  height: 100%;
  @media (max-width: @screen-xs-max) {
    margin-left: -( @grid-padding / 2 );
  }
  
  > ul {
    padding: 0;
    margin: 0;
    height: 100%;
    
    > li {
      display: inline;
      
      > a {
        display: table;
        float: left;
        height: 100%;
        .heading-font;
        text-align: center;
        line-height: 1.3;
        padding-left: 10px;
        padding-right: 10px;
        .transition(~'background-color .2s, color .2s');
        @media (min-width: @screen-sm) {
          min-width: 80px;
        }
        &.active, &:hover, &:focus {
  				background: @brand-primary-darker;
  				text-decoration: none;
  			}
      }
    }
  }
  @media (max-width: @screen-xs-max) {
      > ul {
        display: table;
        width: 100%;
        table-layout: fixed;
        > li {
          display: table-cell;
          > a {
            width: 100%;
            padding: 0;
          }
        }
      }
    &.vertical-scroll {
      text-align: center;
      > ul {
        display: inline-block;
        max-width: 100%;
        width: auto;
        overflow-x: auto;
        overflow-y: hidden;
        &::-webkit-scrollbar { 
            display: none; 
        }
        > li > a {
          height: 45px;
          padding-left: 10px;
          padding-right: 10px;
        }
      } 
    }
  }
}

.site-header {
  .nav-item-inner {
    vertical-align: middle;
    display: table-cell;
  }
  .nav-item-icon {
    display: block;
    font-size: 21px;
  }
  .nav-item-label {
    display: block;
    font-size: ( @font-size-small - 1 );
    white-space: nowrap;
    overflow: hidden;
    .animate-on-scroll(height, 14px, 0);
  }
  
  @media (max-width: @screen-xs-max) {
    .nav-item-icon {
      font-size: 14px;
    }
    .nav-item-label {
      height: auto;
    }
  }
  
  //Tooltips
  .secondary-menu {
    .tooltip {
      .tooltip-arrow {
        border-bottom-color: @brand-primary;
      }
      .tooltip-inner {
        min-width: 0;
        font-size: @font-size-small;
        .heading-font;
        background-color: @brand-primary;
      }
    }
  }
  .not-compact&,
  .touchevents & {
    .secondary-menu {
      .tooltip {
        display: none !important;
      }
    }
  }
  @media (max-width: @screen-xs-max) {
    .tooltip {
      display: none !important;
    }
  }
}




/*
nav.secondary-menu {
	padding: 0px;
	list-style-type: none;
	
	&.show-submenu {
		height: 60px;
	}
	> ul {
  	padding: 0;
	}
	li {
		float: left;
		
		a {
			float: left;
			padding: 0 15px;
			font-size: @font-size-small;
			font-family: @headings-font-family;
    	font-weight: bold;
    	font-style: italic;
    	-webkit-font-smoothing: antialiased;
			text-decoration: none;
			color: #fff;
			.transition(background-color 200ms linear);
			
			&:visited,
			&:hover,
			&:focus {
				color: #fff;
			}
			
			&:hover {
				background: @brand-primary-darker;
			}
		}
		
		.submenu {
			display: none;
		  border-bottom: 1px solid darken(@brand-primary-light, 10%);;
		}
		
		&.active {
			a {
				background: @brand-primary-darker;
			}
			&.parent {
  			a {
    			background: @brand-primary-light;
    			color: @gray-darker;
  			}
			}
			
			.submenu {
				display: block;
				position: absolute;
				top: 30px;
				left: 0;
				width: 100%;
				background: @brand-primary-light;
				
				li {
					a {
						color: @gray-darker;
						
						&:hover {
							background: #fff;
						}
					}
				
					&.active a {
  					background: #fff;
						color: @gray-dark;
					}
				}
			}
		}
	}
	@media (max-width: @screen-xs-max) {
  	display: none;
	}
}
*/