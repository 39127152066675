@import "bootstrap/less/print.less";

@media print {
  .site-header, .mega-menu {
    display: none !important;
  }
  
  .hero {
    min-height: 200px !important;
    height: auto !important;
  }
}
