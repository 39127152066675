.partner-form {
	width: 440px;
	.clearfix;
	
	textarea {
		height: 200px;
	}
	
	.button {
		float: right;
	}
}