.widget.advanced-search {
	textarea.domainnames {
		height: 200px;
		margin: 0;
	}

	.tld-selector {
		box-sizing: border-box;
		overflow-y: scroll;
		width: 100%;
		height: 295px;
		margin: 15px 0 15px 0;
		padding: 0;
		background: #ddd;
		background-image: none;
		border: 1px solid @gray-light;
		
		.tld-categories {
			list-style: none;
			margin: 0;
			
			.category {
				margin: 0 0 1px 0;
				
				&:last-child {
					margin: 0;
				}
				
				.daredevel-tree-anchor {
					top: 13px;
					left: 5px;
				}
				
				label {
					padding: 12px 3px 10px 20px;
					display: block;
					background: #ededed;
				}
				
				input {
					vertical-align: middle;
				}
				
				h3 {
					display: inline;
					padding: 0;
					font-size: 14px;
					vertical-align: bottom;
				}
				
				.tlds {
					list-style: none;
					margin: 0;
					
					.tld {
						margin: 0;
						padding: 0 0 0 30px;
						border-top: 1px solid #ccc;
						
						label {
							padding: 8px 0;
							background: transparent;
							border: none;
							
							span {
								font-size: 14px;
							}
						}
					}
				}
			}
		}
	}
	
	.button {
		float: right;
	}
}