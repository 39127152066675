html {
  .hero.error-page {
    height: 70vh;
    display: flex;
    justify-content: center;
    transform: translateY(10%);
    .inner {
      h1,
      p {
        text-shadow: none;
        color: black;
      }
      a {
        color: @link-color;
      }
      h1 {
        font-size: 4em;
        padding: 0.4em;
      }
      p {
        font-size: 1.5em;
      }
      @media (max-width: @screen-sm) {
        h2 {
          font-size: 2em;
        }
        p {
          font-size: 1em;
        }
      }
    }
  }
}
