@import "bootstrap/less/panels.less";
.panel-body.page-content {
    @media screen and (min-width: @screen-md) {
        padding-right: 30px;
        padding-left: 30px;
    }
}

.panel {
    box-shadow: none;
}

.reseller-information {
    h3 {
        margin-top: 22px;
    }
    ul {
        margin-bottom: 0;
        li {
            padding-top: 10px;
            padding-bottom: 10px;
            border: none;
            &:nth-child(odd) {
                background-color: @brand-grey;
            }
        }
    }
    dt {
        width: 50%;
        display: inline-block;
        padding-left: 12px;
    }
    dd {
        width: 50%;
        float: right;
    }
}

.collapsible {
    h2 {
        display: inline-block;
        cursor: pointer;
        i {
            color: @gray;
            font-weight: 800;
            font-size: 24px;
        }
        &:hover {
            color: #888;
            text-decoration: none;
            i {
              color: #888;
            }
        }
    }
}

.description-list-panel {
    header {
        div {
            display: flex;
        }
        .panel-heading {
            width: 100%;
        }
        .flag {
            padding-right: 41px;
            margin-right: 13px;
            margin-bottom: -4px;
        }
    }
    .container-loading {
        min-height: 180px;
        &:before {
            margin-top: 40px;
        }
    }
    .panel-body {
        min-height: 180px;
        padding-top: 0;
        padding-bottom: 0;
    }
    dl {
        margin-bottom: 0;
        padding-top: 16px;
        padding-bottom: 6px;
        dt {
            width: 50%;
            display: inline-block;
            margin-top: 0;
            margin-bottom: 8px;
        }
        dd {
            width: 50%;
            text-align: right;
            float: right;
        }
    }
}

panel-item,
news-panel {
    .panel-footer:empty {
        display: none;
    }
}

.panel-header,
.panel-footer {
    a:hover,
    a:focus {
        text-decoration: none;
        color: #888;
        h1,
        h2,
        h3,
        h4 {
            text-decoration: none;
            color: #777;
        }
    }
    .panel-heading {
        margin-bottom: 0;
        margin-top: 0;
    }
}

.panel-body {
    .hyphens();
}

.panel-subheader {
    .panel-heading {
        margin-bottom: 0;
        font-size: inherit;
    }
    &.collapsable {
        .btn;
        .btn-bar;
        background: transparent;
        padding: 0;
        &:hover,
        &:focus {
            outline: none;
            background-color: @table-bg-accent;
        }
    }
}

.panel-footer {
    .clearfix;
}

.alert-panel {
    &.panel-warning {
        &:extend(.alert all, .alert-warning all);
    }
    &.panel-info {
        &:extend(.alert all, .alert-info all);
    }
    &.panel-danger {
        &:extend(.alert all, .alert-danger all);
    }
    &.panel-success {
        &:extend(.alert all, .alert-success all);
    }
    &.panel-warning,
    &.panel-info,
    &.panel-danger,
    &.panel-success {
        padding: 0;
        .panel-body {
            background-color: #fff;
        }
    }
    && .panel-header {
        border-top: none;
    }
}

.panel-shade {
    background: rgba(255, 255, 255, .7);
}

.panel-variant(@border-color) {
    border-top-width: 0;
    border-color: @panel-default-border;
    .panel-header {
        .transition(border-color .5s);
        border-top: 7px solid @border-color;
        margin-left: -1px;
        margin-right: -1px;
    }
}

.panel-inverted-variant(@border;
@text-color;
@bg-color;
@bg-footer: @border) {
    border-color: @border;
    background-color: @bg-color;
    color: @text-color; // box-shadow: 0 0 0 @text-color;
    a {
        color: contrast(@bg-color);
    }
    a& {
        color: @text-color;
    }
    a&:hover {
        text-decoration: none;
        background-color: darken(@bg-color, 20%);
        color: contrast(darken(@bg-color, 10%));
    }
    div& .panel-heading {
        border-top: 1px solid @border;
    }
    .panel-heading {
        background-color: transparent;
        border-color: @border;
        border-bottom: none;
        +.panel-body {
            border-top: none;
        }
    }
    .panel-footer {
        // background-color: @bg-footer;
        // border-top-color: @border;
        a {
            color: contrast(darken(@bg-footer, 10%));
        }
    }
    .list-group-item {
        background-color: @bg-color;
        border-color: @border;
        color: @text-color;
        &:hover,
        &:focus {
            background-color: @border;
            &,
            .news-title {
                color: #fff !important;
            }
        }
    }
}

.panel-inverted {
    margin-bottom: (@line-height-computed / 2);
    min-height: (@unit * 8);
    border-top-width: 0;
    a& {
        display: block;
        border-left-width: @half-unit;
        border-top-width: 1px;
        &:hover,
        &:focus {
            .panel-title {
                color: inherit;
            }
        }
    }
    position: relative;
    .modal-content & {
        margin-bottom: 0;
    }
    a&:hover {
        cursor: pointer;
    }
    h1,
    h2,
    h3,
    h4 {
        font-weight: 400;
        padding-top: 5px;
        color: inherit;
    }
    .panel-header {
        h1,
        h2,
        h3,
        h4 {
            padding-top: 10px;
        }
    }
    .panel-title {
        .type-size(14);
        margin: 0;
        padding-top: 0;
        .fa {
            padding-right: @half-unit;
        }
    }
    .panel-body {
        padding: 30px @panel-body-padding @panel-body-padding;
    }
    .panel-header {
        +.panel-body {
            padding-top: 15px;
        }
    }
    .panel-heading {
        padding-bottom: @unit;
        +.panel-body {
            padding-top: 5px;
        }
    }
    &.panel-double {
        min-height: unit( ((@unit * 16) + (@line-height-computed / 2)), px);
        .big-number {
            .type-size(48);
        }
    }
    &.panel-default {
        .panel-inverted-variant(@panel-default-border;
        @panel-default-text;
        @panel-default-heading-bg);
    }
    &.panel-primary {
        .panel-inverted-variant(@panel-primary-border;
        @panel-primary-text;
        @panel-primary-heading-bg;
        @brand-primary-medium);
    }
    &.panel-success {
        .panel-inverted-variant(@panel-success-border;
        @panel-success-text;
        @panel-success-heading-bg);
    }
    &.panel-info {
        .panel-inverted-variant(@panel-info-border;
        @panel-info-text;
        @panel-info-heading-bg);
    }
    &.panel-warning {
        .panel-inverted-variant(@panel-warning-border;
        @panel-warning-text;
        @panel-warning-heading-bg);
    }
    &.panel-danger {
        .panel-inverted-variant(@panel-danger-border;
        @panel-danger-text;
        @panel-danger-heading-bg);
    }
    &.panel-warning-dark {
        .panel-inverted-variant(@panel-warning-text;
        #fff;
        desaturate(@brand-warning, 15%);
        @panel-warning-heading-bg);
    }
    &.panel-info-dark {
        .panel-inverted-variant(@panel-info-text;
        #fff;
        @brand-info;
        @panel-info-heading-bg);
    }
    &.panel-success-dark {
        .panel-inverted-variant(@panel-success-text;
        #fff;
        @brand-success;
        @panel-success-heading-bg);
    }
    &.panel-danger-dark {
        .panel-inverted-variant(darken(@panel-danger-text, 10%);
        #fff;
        @brand-danger;
        @panel-danger-heading-bg);
    }
    &.panel-default-dark {
        .panel-inverted-variant(@gray-darker;
        #fff;
        @gray;
        @gray-light);
    }
}

.panel-primary {
    .panel-variant(@panel-primary-border);
}

.panel-warning {
    .panel-variant(@panel-warning-border);
}

.panel-success {
    .panel-variant(@panel-success-border);
}

.panel-danger {
    .panel-variant(@panel-danger-border);
}

.panel-info {
    .panel-variant(@panel-info-border);
}

.panel-default.panel-disabled {
    .panel-variant(@panel-default-border);
}

.panel-warning-dark {
    .panel-variant(@panel-warning-text);
}

.panel-info-dark {
    .panel-variant(@panel-info-text);
}

.panel-success-dark {
    .panel-variant(@panel-success-text);
}

.panel-danger-dark {
    .panel-variant(@panel-danger-text);
}

.panel-default-dark {
    .panel-variant(@gray-darker);
}

.panel-grey-light {
    background-color: @light-grey;
    border: 1px solid @brand-grey-custom;
}

//Panel footer
.edit-controls {
    float: right;
}

.panel-loading {
    position: relative;
    .loading-spinner(70px, #000, 100px);
    .panel-body {
        min-height: 200px;
    }
    .panel-body,
    .panel-footer {
        .blur(on);
    }
}

//Panel Overlay
.panel-disabled {
    position: relative;
    min-height: 110px;
    .panel-body,
    .panel-footer {
        .blur(on);
        opacity: .1;
    }
    .panel-footer.panel-footer-enabled {
        .blur(off);
        opacity: 1;
    }
    fieldset[disabled] {
        position: relative;
    }
    @media screen and (max-width: @screen-xs-max) {
        max-height: 420px;
        overflow: hidden;
    }
}

.panel-body,
.panel-footer {
    .transition(opacity .5s);
}

pending-changes>div {
    .transition(opacity .5s);
}

.panel-disabled-info {
    opacity: 0;
}

#domain_contacts.panel-disabled {
    position: relative;
    .panel-disabled-info {
        opacity: 0;
    }
    &.in .panel-disabled-info {
        opacity: 1;
    }
    .panel-overlay {
        display: block;
        width: 100%;
        height: 300px;
        margin-bottom: 30px;
        background: #fff;
        border: 1px solid @panel-default-border;
        border-top: 7px solid @panel-default-border;
    }
    section {
        .transition(all .5s);
    }
    section.panel-disabled {
        opacity: 0;
        height: 0;
        min-height: 0;
        margin-bottom: 0;
        overflow: hidden;
    }
    &.panel-disabled-warning .panel-overlay {
        border-top-color: @panel-warning-border;
    }
    &.panel-disabled-error .panel-overlay {
        border-top-color: @panel-danger-border;
    }
    &.panel-disabled-pending .panel-overlay {
        border-top-color: @panel-default-border;
    }
    &.panel-disabled-info .panel-overlay {
        border-top-color: @panel-info-border;
    }
    &.panel-disabled-success .panel-overlay {
        border-top-color: @panel-success-border;
    }
    &.panel-disabled>.row {
        .panel {
            background: transparent;
            border-color: transparent;
        }
        .panel-header {
            border-color: transparent;
        }
    }
}

.panel-invalid {
    .panel-header {
        border-top: 7px solid #96959a;
    }
}

//Directive
panel-list {
    display: block;
    line-height: 0;
    .panel-item {
        .make-xs-column(12);
        .make-sm-column(6);
        .make-md-column(4);
        display: inline-block;
        font-size: @font-size-base;
        min-height: 0;
        float: none !important;
        vertical-align: top;
        line-height: 1.5;
        .panel {
            margin-bottom: @line-height-computed;
        }
        .panel-body {
            p:last-child {
                margin-bottom: 0;
            }
        }
    }
    @media (min-width: @screen-sm) {
        &.same-height {
            .panel-container {
                min-height: 217px;
            }
            .panel-footer {
                min-height: 60px;
            }
        }
        .panel-item {
            &.pull-right {
                float: right !important;
            }
        }
        .double-height {
            float: left !important;
            .panel-container {
                min-height: 484px;
            }
        }
        .double-width {
            .make-sm-column(12);
            .make-md-column(8);
        }
    }
}

.panel-item {
    .search-widget {
        padding-top: 2px;
        position: relative;
        top: 6px;
        .input-group {
            height: 100%;
        }
    }
}

.panel-info {
    border: 1px solid #ddd;
    border-top: none;
    .panel-header {
        border-top: 7px solid @complement-color-lighter;
    }
}

.panel-footer {
    background-color: #f5f5f5;
    a {
        color: #474747;
    }
}