.popup.searchmode {
	margin-top: -1px;
	padding: 5px;
	
	li {
		a {
			display: block;
			padding: 10px;
			padding-left: 24px;
			text-decoration: none;
			
			&:hover {
				background: #eee;
				color: #0077b2;
			}
		}
		
		&.active a {
			padding-left: 0;
			
			&:before {
				display: inline-block;
				font-family: FontAwesome;
				font-style: normal;
				font-weight: normal;
				line-height: 1;
				-webkit-font-smoothing: antialiased;
				-moz-osx-font-smoothing: grayscale;
				content: "\f0da";
				padding: 0 10px;
			}
		}
	}
}