ol, ul {
	list-style: none;
	padding-left: 0;
	main article & {
  	list-style: disc;
  	padding-left: 20px;
	}
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	width: 100%;
}