@import "bootstrap/less/alerts.less";

.alert {
  position: relative;
  border-left-width: 60px;
  .glyph;
  .glyph-info;
  &:before {
    position: absolute;
    top: 50%;
    .transform(translate(0, -50%));
    left: -48px;
    font-size: 42px;
  }
  @media (max-width: @screen-xs-max) {
    border-left-width: 35px;
    &:before {
      font-size: 22px;
      left: -26px;
    }
    font-size: @font-size-small;
    h1, h2, h3 {
      font-size: @font-size-base;
    }
  }
}

.alert-danger {
  .glyph-error;
  h1, h2, h3, h4, h5 { color: @alert-danger-text;}
}
.alert-warning {
  .glyph-warning; 
  &:before {margin-left: -3px; margin-top: 1px;}
  h1, h2, h3, h4, h5 { color: @alert-warning-text;}
}
.alert-success {
  .glyph-success;
  h1, h2, h3, h4, h5 { color: @alert-success-text;}
}
.alert-info {
  .glyph-info;
  h1, h2, h3, h4, h5 { color: @alert-info-text;}
}

.alert-aside {
  margin-bottom: 15px;
  @media (min-width: @screen-md) {
    float: left;
    margin-left: -48px;
    margin-right: @grid-padding;
    .alert-danger & { background-color: @panel-danger-border; }
    .alert-warning & { background-color: @panel-warning-border; }
    .alert-success & { background-color: @panel-success-border; }
    .alert-info & { background-color: @panel-info-border; }
  }
}

//System Alert/Notification
.system-alerts {
  position: fixed !important;
  top: 0;
  width: 50%;
  left: 50%;
  z-index: 2000;
  .transform(translate3d(-50%,0,0));
  @media (max-width: @screen-xs-max) {
    width: 100%;
    top: -15px;
  }
}
.system-alert {
  position: relative;
  top: -15px;
  opacity: 0;
  max-height: 0;
  padding-top: 0;
  padding-bottom: 0;
  box-shadow: 0 0 10px rgba(0,0,0,.6);
  margin-bottom: @unit;
  .transition(all .8s);
  &.in {
    opacity: 1;
    top: 15px;
    max-height: 300px;
    padding-top: 15px;
    padding-bottom: 15px;
  }
  &.out {
    opacity: 0;
    top: 45px;
    max-height: 300px;
    padding-top: 15px;
    padding-bottom: 15px;
  }
}

.system-notification {
  .type-size(@font-size-large);
  font-weight: 200;
  color: @gray-light;
}

.site-updated {
  position: fixed !important;
  bottom: 0;
  width: 50%;
  left: 50%;
  z-index: 2000;
  .transform(translate3d(-50%,0,0));
  @media (max-width: @screen-xs-max) {
    width: 100%;
    bottom: -15px;
  }
}
.site-updated-alert {
  position: relative;
  bottom: -15px;
  opacity: 0;
  max-height: 0;
  padding-top: 0;
  padding-bottom: 0;
  box-shadow: 0 0 10px rgba(0,0,0,.6);
  margin-bottom: @unit;
  .transition(all .8s);
  &.in {
    opacity: 1;
    bottom: 15px;
    max-height: 300px;
    padding-top: 15px;
    padding-bottom: 15px;
  }
  &.out {
    opacity: 0;
    bottom: 45px;
    max-height: 300px;
    padding-top: 15px;
    padding-bottom: 15px;
  }
}


//Global Alert (site wide alert)
.global-alert {
  margin-top: @dub-unit;
  @media (min-width: @screen-sm) {
    .global-alert-action {
      .min-3; 
      position: absolute;
      top: 50%;
      .translate(0, -50%);
      right: 0;
      width: 25%;
      .btn {
        min-height: 57px;
      }
    }
  }
  @media (max-width: @screen-xs-max) {
    margin-top: 0;
    margin-left: -@grid-padding;
    margin-right: -@grid-padding;
    font-size: @font-size-small;
    border-right: none;
    h1, h2, h3 {
      font-size: @font-size-base;
    }
    .global-alert-action .btn {
      margin-top: 5px;
      clear: both;
      .btn-sm;
    }
  }
}
