.carousel-wrapper {
	position: relative;
	height: 100vh;
	margin-top: -200px;
	margin-bottom: 50px;
	background-color: #fff;
	@media screen and (max-width: @screen-sm-max) {
    height: auto;
    margin-top: -18px;
  }
  
  @media (max-width: @screen-xs) {
    position: inherit;
    height: inherit;
    margin-top: inherit;
    margin-bottom: inherit;
	}
}

.carousel-slider {
  position: absolute;
	box-sizing: border-box;
	overflow: hidden;
	color: #000;
	top: 132px;
	bottom: 25%;
	left: 0;
  right: 0;
  margin-top: 15px;
	background-position: center center;
	background-size: cover;
	border-bottom: 1px solid @gray-lighter;
	@media screen and (max-width: @screen-lg-max) {
    bottom: 20%;
  }
	@media screen and (max-width: @screen-md-max) {
    bottom: 15%;
  }
	@media screen and (max-width: @screen-sm-max) {
    position: relative;
    top: -68px; // 200-132
    bottom: 0;
  }
  
  @media (max-width: @screen-xs) {
    top: inherit;
  }

  .carousel-inner {
    height: 100%;
    background-color: #fff;

    .item {
      height: 100%;
      transition: transform 0.8s ease-in-out;

      @media (max-width: @screen-xs) {
        height: inherit;
      }

      > .item-desktop {
        height: 100%;
        background-repeat: no-repeat;
        background-size: cover;
        @media screen and (max-width: @screen-lg-max) {
          background-position: center center;
        }
        @media screen and (max-width: @screen-sm-max) {
          display: none;
          background-image: none!important;
        }
      }
      > .item-mobile {
        display: none;
        @media screen and (max-width: @screen-sm-max) {
          display: block;
        }
      }
    }
  }

  // Only used in .item-desktop
  .box {
    max-width: 420px;
    text-align: left;
    padding: 24px;
    display: inline-block;
    top: 50%;
    transform: translateY(-50%);
    position: relative;
  }

  .carousel {
    position: relative;
    height: 100%;
  }
  .carousel-caption {
    .container;
    &::before,
    &::after {
      content: none;
    }
    position: relative;
    height: 100%;
    left: 0;
    right: 0;
    max-width: 80%;
    padding: 50px 0;
    bottom: auto;
    text-align: left;
    text-shadow: none;
    color: #000;

    .btn {
      margin-top: 2.5rem;
      font-size: 1.2em;
      font-family: roboto, sans-serif;
      padding: 6px 18px;
      background-color: @gray-dark;
      color: white;
      &:hover {
        background-color: @gray;
      }
    }

    p  {
      color: #000;
      font-size: 16px;
      line-height: 1.5;
      &.h1-size, &.h2-size, &.h3-size, &.h4-size, &.h5-size, &.h6-size {
        line-height: normal;
        margin-bottom: 16px;
      }
    }
    
    h1, h2, h3, h4, h5, h6 {
      color: #000;
      padding: 0;
      margin: 0;
      margin-bottom: 16px;
      text-transform: none;
    }
    
    h1, p.h1-size { font-size: 60px; }
    h2, p.h2-size { font-size: 50px; }
    h3, p.h3-size { font-size: 40px; }
    h4, p.h4-size { font-size: 30px; }
    h5, p.h5-size { font-size: 25px; }
    h6, p.h6-size { font-size: 20px; }

    @media screen and (max-width: @screen-lg-max) {
      h1, p.h1-size { font-size: 55px; }
      h2, p.h2-size { font-size: 46px; }
      h3, p.h3-size { font-size: 37px; }
      h4, p.h4-size { font-size: 28px; }
      h5, p.h5-size { font-size: 24px; }
      h6, p.h6-size { font-size: 20px; }
    }
    @media screen and (max-width: @screen-md-max) {
      h1, p.h1-size { font-size: 50px; }
      h2, p.h2-size { font-size: 42px; }
      h3, p.h3-size { font-size: 34px; }
      h4, p.h4-size { font-size: 26px; }
      h5, p.h5-size { font-size: 22px; }
      h6, p.h6-size { font-size: 19px; }
    }

    @media screen and (max-width: @screen-sm-max) {
      width: 100%;
      max-width: 100%;
      padding: 30px;
      min-height: 400px;
      h1, p.h1-size { font-size: 45px; }
      h2, p.h2-size { font-size: 38px; }
      h3, p.h3-size { font-size: 31px; }
      h4, p.h4-size { font-size: 24px; }
      h5, p.h5-size { font-size: 21px; }
      h6, p.h6-size { font-size: 18px; }
    }
    @media screen and (max-width: @screen-xs) {
      min-height: inherit;
      height: 325px;
    }
  }

  .carousel-indicators {
    margin-bottom: 0;
    li {
      margin: 1px 6px;
    }

    @media screen and (max-width: @screen-sm-max) {
      li {
        border-color: @gray-darker;
      }
      .active {
        background-color: @gray-darker;
      }
    }
  }

  .fa-chevron-circle-right,
  .fa-chevron-circle-left {
    position: absolute;
    top: 50%;
    z-index: 5;
    display: inline-block;
    width: 30px;
    height: 30px;
    margin-top: -35px;
    margin-left: -15px;
    font-size: 42px;
    box-shadow: none;
    @media screen and (max-width: @screen-sm-max) {
      font-size: 30px;
    }
  }

  .carousel-control {
    width: 10%;
    &.right,
    &.left {
      background-image: none;
      text-shadow: none;
      @media screen and (max-width: @screen-sm-max) {
        display: none;
      }
    }
    &:hover {
      opacity: none;
    }
  }
}
