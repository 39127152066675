@import "bootstrap/less/dropdowns.less";

.dropdown-menu {
  margin-top: -1px;
  min-width: @dropdown-min-width;
  padding: 0;
  text-align: left;
  &.panel {
    padding-top: 0;
    padding-bottom: 0;
  }
  .panel-heading {
    font-size: 12px;
    margin: 0;
  }
  > li > label {
    &:extend(.dropdown-menu > li > a all);
    margin-bottom: 0;
    .text-truncate();
  }
  > li > button {
    [dropdown] & {
      text-align: left;
      margin: 0 !important; 
      padding: 10px;
      font-size: 13px !important;
    }
  }
  > li > [btn-radio] {
    padding-left: 20px !important;
  }
  > li.active > [btn-radio] {
    position: relative;
    &:before {
      position: absolute;
      top: 0;
      left: 0;
      content: '';
      border: 18px solid transparent;
      border-left: 10px solid @brand-primary;
    }
  }
}

.dropdown-nested-menu {
  &:extend(.dropdown-menu all);
  display: block;
  position: static;
  border-top: none;
  margin-left: 0;
  margin-right: 0;
  width: 100%;
  max-width: 500px;
  box-shadow: none;
  font-size: @font-size-small;
  .divider {
    margin: 5.5px 0;
  }
  &.scrollable {
    overflow-y: auto;
    max-height: 150px;
    box-shadow: 0px -3px 2px rgba(0,0,0,.1) inset;
  }
}

.dropdown-menu-static {
  position: static;
  box-shadow: none;
  &.collapsing,
  &.collapse.in {
    display: block;
  }
}


.dropdown-menu li > .btn {
  border: none;
}

.dropdown-item-group {
  position: relative;
  padding-right: 35px;
}

.dropdown-item-addon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translate(0, -50%);
  font-size: 20px;
  + a {
    padding-right: 7px !important;
  }
}

@media screen and (max-width: @screen-xs-max) {
  .dropdown {
    position: static;
  }
}
