.mega-menu {
  a {
    &:hover, &:focus {
      text-decoration: none;
      h2 {
        text-decoration: none;
      }
    }
  }


  
  font-size:1em;
  .site-menu & {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background-color: @gray-lightest;
    border: 1px solid @gray;
    text-align: left;
    z-index: (@zindex-navbar-fixed + 2);
    &.container {
      height: auto;
    }
    @media (max-width: @screen-xs-max) {
      left: 0;
      right: 0;
      border: none;
      border-bottom: 1px solid @gray;
      height: 80vh;
      overflow-y: auto;
    }
  }

  #site-menu.site-menu & {
  @media (max-width: @screen-xs-max) {
    height: 80vh;
  }
}

  .site-header .site-header-primary & a {
    color: @gray-darker;
    &:hover, &:focus {
      color: @link-hover-color;
    }
  }
  .public-navigation-body {
    @media (min-width: @screen-sm) {
      height: auto !important;
      display: block;
    }
  }
  .public-primary-navigation {
    .clearfix;
    padding: 15px @grid-padding 30px;
    h2 {padding-top: 0;}
    @media (min-width: @screen-md) {
      .make-row();
      > div {
        border-right: 1px solid @gray-light;
        &:last-of-type {
          border-right: none;
        }
      }
    }
  }
  ul.navigation {
    margin: 0;
    padding: 0;
    margin-bottom: 10px;
    list-style: none;
    > li {
      > a {
        display: block;
        padding: 3px;
        text-decoration: none;
        &:before {
          .fa;
          content: @fa-var-caret-right;
          margin-right: 10px;
        }
      }
      
      @media (max-width: @screen-xs-max) {
        > a {
          padding: 6px;
          &:hover, &:focus {
            background-color: @gray-lighter; 
          }
        }
        &:nth-child(2n) {
          > a {
            background-color: lighten(@gray-lighter, 2%); 
            &:hover, &:focus {
              background-color: @gray-lighter; 
            }
          }
        }
      }
    }
    @media (min-width: @screen-md) {
      min-height: 270px;
    }
  }
  .more {
    display: block;
    margin-bottom: 15px;
  }
  h2 {
    font-weight: 700;
    font-style: italic;
    font-size: @font-size-h4;
  }
  .public-navigation-footer {
    .clearfix;
    padding: 15px 0;
    border-top: 1px solid @gray-light;
    background-color: @gray-lighter;
  }
  .public-secondary-navigation {
    > ul {
      list-style: none;
      padding: 0;
      display: table;
      width: 100%;
      > li {
        display: table-cell;
        height: 100%;
        .icon-btn-icon {
          font-size: 39px;
          line-height: 46px;
        }
        @media (max-width: @screen-xs-max) {
          margin-top: 10px;
          .icon-btn {
            padding: 6px;
            font-size: 10px;
            .icon-btn-icon {
              font-size: 21px;
              line-height: 29px;
            }
          }
        }
      }
    }
  }

  .public-search {
    width: 100%;
  }
}