date-picker, [datepicker] {
  .dropdown-menu {
    padding: 5px;
    table {outline: none;}
  }
  &.input-group-sm {
    .form-control, .input-group-addon {
      .input-sm;
    }
    .btn {
      .btn-sm;
    }
  }
  
  table .btn-default {
    background: transparent;
    border-color: transparent;
    &.btn-info {
      background-color: @brand-primary;
    }
    padding: 5px 7px;
    &.disabled, &[disabled] {
      background: #eee;
      border-color: #fff;
    }
  }
  .btn-info {
    &:hover, &:focus, &:active, 
    &.active, .open .dropdown-toggle& {
      .text-info {
        color: #fff;
      }
    }
  }
  table em {
    font-size: 10px;
    color: #fff;
    font-style: normal;
    display: inline-block;
    background: #bbb;
    border-radius: 28px;
    padding: 4px 5px;
    vertical-align: sub;
  }
  
  [ng-switch-when="day"] .btn {
    font-weight: bold;
  }
}