.link .icon {
	padding-right: 5px;
}

.icon {
	color: #404040;
}

.icon:before {
	display: inline;
	width: 1.3em;
	height: 1em;
	font-family: FontAwesome;
	text-align: center;
}

.icon.pin:before {
	content: "\f041";
}

.icon.cog:before {
	content: "\f013";
}

.icon.cart:before {
	content: "\f07a";
}