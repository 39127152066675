.widget.search {

	.form-item.compound .buttons .button .button-inner{
		.button-icon {
			i {
				color: @gray;
			}
		}
	}
	.prefix {
		font-size: 20px;
		font-weight: bold;
		line-height: 40px;
		padding-right: 15px;
		display: table-cell;
		width: 1%;
	}
	
	.suffix {
		font-size: 20px;
		font-weight: bold;
		line-height: 40px;
		padding-left: 15px;
		display: table-cell;
		width: 1%;
	}
}

.content-domain-info {
	.search-widget {
		background-color: @brand-grey-light;
	}
}