


//Loading Spinner
.loading-spinner(@size, @color: #000, @offsetTop: 0) {
  &:before {
    .fa;
    content: "" !important;
    .fa-spin;
    position: absolute;
    left: 50%;
    top: 0;
    margin-top: @offsetTop;
    z-index: 100;
    border-radius: 100%;
    width: @size;
    height: @size !important;
    margin-left: (-@size / 2);
    .animation(fa-spin 1s infinite linear);
    box-shadow: 0 -(@size / 10) 0 fade(@color, 20%);
  }
  &.loading-inverse:before {
    box-shadow: 0 -(@size / 10) 0 fade(contrast(@color), 20%);
  }
  &:after {
    content: attr(data-loadable-text);
    .status-font-style;
    text-align: center;
    display: block;
    position: absolute;
    z-index: 100;
    left: 0;
    top: 0;
    height: 2em;
    width: 100%;
    margin-top: ( @size + @offsetTop + (@size*0.2) );
  }
  &.loading-inverse:after {
    color: contrast(@color);
  }
}

//Spinner sizes
.loading-sm {
  position: relative;
  min-height: 195px;
  .loading-spinner(50px, #000, 65px);
}
.loading-md {
  position: relative;
  min-height: 260px;
  .loading-spinner(80px, #000, 80px);
}
.loading-lg {
  position: relative;
  min-height: 340px;
  .loading-spinner(100px, #000, 100px);
}

.container-loading {
  position: relative;
  min-height: 350px;
  .loading-spinner(100px, #000, 80px);
  > * {
    .blur(on);
    opacity: .3;
  }
  > loadable-error {
    .blur(off);
    opacity: 1;
  }
}


.loadable-button.loading[data-loadable-text] {
    .loadable-button-label {
        display: none;
    }
    &:after {
        content: attr(data-loadable-text);        
    }
}

//Table load spinner and overlay
.table,
.table-container {
  position: relative;
  z-index: 3;
  opacity: 1;
  tbody {
    .blur(off);
    .transition(all .3s);
  }
  &.loading {
    overflow: hidden !important;
    tbody {
      opacity: .4;
      .blur(on);
      pointer-events: none;
      tr:last-child {
        &:nth-child(-n+10) {height: 200px; }
        &:nth-child(-n+5) {height: 300px; }
      }  
    }
    .loading-spinner(100px, #000, 100px);
  }
}

.loading-error {
  &:before,
  &:after {
    display: none !important;
  }
/*
  &&:before {
    .animation(~'none !important');
    color: @brand-danger !important;
    text-shadow: 0 0 5px fade(#000,30%) !important;
    content: @fa-var-exclamation-circle !important;
  }
*/
}

.loadable-error {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  height: 100%;
  max-height: 350px;
  .loading-inverse & {
    color: #fff;
    * { text-shadow: none; }
    a {color: @gray-lighter;}
  }
}


