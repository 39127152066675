.widget.domain-generator {
	.col.first {
		box-sizing: border-box;
		width: 100%;
		margin-right: -200px;
		padding-right: 215px;
	}
	
	.col.last {
		width: 200px;
	}
	
	.prefix, .sufix, .dashes {
		float: left;
		margin-right: 15px;
		margin-bottom: 0;
	}
	
	.generate-button {
		width: 100%;
	}
}