/* Icons
-------------------------------------------------------------- */

.icon-btn {
  .btn;
  .btn-link;
  display: inline-table;
  position: relative;
  width: 100%;
  &:hover, &:focus {
    text-decoration: none;
  }
  .icon-btn-inner {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
  }
  .icon-btn-icon {
    display: block;
    font-size: 32px;
    line-height: 36px;
  }
  .icon-btn-label {
    display: block;
    font-size: @font-size-small;
    .heading-font;
    white-space: nowrap;
    
    .secondary-menu &,
    .user-menu & {
      height: 20px;
      overflow: hidden;
      .translate3d(0,0,0);
      .transition(height .2s);
      .compact & {
        height: 0;
      }
    }
  }
  .badge {
    position: absolute;
    top: 7px;
    right: 7px;
    font-size: 10px;
    padding: 3px 5px;
    z-index: 1;
  }
  
} 
.icon-block {
  position: relative;
  display: inline-block;
  vertical-align: top;
  text-align: center;
  padding: 0;
  margin: 0.6125em;
  margin-bottom: 1em;
  cursor: pointer;
  &[disabled] {
    opacity: .6;
  }
  &:focus {
    outline: none;
  }
  &.icon-large {
    .type-size(26);
    line-height: 54px;
    width: 64px;
    height: 64px;
    background: #fff;
    border-radius: 60px;
    border: 5px solid lighten(@gray-light, 5%);
    i {top: 2px;}
    > .icon-block-label {
      display: block;
      font-family: @headings-font-family;
      position: absolute;
      width: 84px;
      top: 60px; left: -15px;
      span {          
        .type-size(11);
        display: block;
        line-height: 1.0625;
        font-weight: 500;
        width: 100%;
        text-align: center;
        margin: 0 auto;
      }
      
    }
    &:not([disabled]):hover, &:not([disabled]):focus, .open &:not([disabled]) {
      .icon-block-label {
        color: @gray-darker !important;
        .fixed-bulk & {
          color: #fff !important;
        }
      }
    }
    @media screen and (max-width: @screen-xs-max) {
      .type-size(15);
      line-height: 37px;
      width: 43px;
      height: 43px;
      background: #fff;
      border-radius: 60px;
      border: 4px solid lighten(@gray-light, 5%);
      margin: 0.3125em 0.7125em 3em;
      > .icon-block-label {
        width: 56px;
        top: 44px; left: -11px;
      }
    }
  }
  .dropup & + .dropdown-menu {
    margin-bottom: -20px;
  }
  

}
.icon-large.icon-default {
    border-color: lighten(@gray, 30%);
  &:not([disabled]):hover, &:not([disabled]):focus, .open &:not([disabled]) {
    background: @gray;
    color: contrast(@gray, @gray-dark, #fff, 50%);
    border-color: darken(@gray, 5%);
  }
}
.icon-large.icon-primary {
    border-color: lighten(@brand-primary, 30%);
  &:not([disabled]):hover, &:not([disabled]):focus, .open &:not([disabled]) {
    background: @brand-primary;
    color: contrast(@brand-primary, @gray-dark, #fff, 50%);
    border-color: darken(@brand-primary, 5%);
  }
}
.icon-large.icon-info {
    border-color: lighten(@brand-info, 30%);
  &:not([disabled]):hover, &:not([disabled]):focus, .open &:not([disabled]) {
    background: @brand-info;
    color: contrast(@brand-info, @gray-dark, #fff, 50%);
    border-color: darken(@brand-info, 5%);
  }
}
.icon-large.icon-success {
    border-color: lighten(@brand-success, 30%);
  &:not([disabled]):hover, &:not([disabled]):focus, .open &:not([disabled]) {
    background: @brand-success;
    color: contrast(@brand-success, @gray-dark, #fff, 50%);
    border-color: darken(@brand-success, 5%);
  }
}
.icon-large.icon-warning {
    border-color: lighten(@brand-warning, 20%);
  &:not([disabled]):hover, &:not([disabled]):focus, .open &:not([disabled]) {
    background: @brand-warning;
    color: contrast(@brand-warning, @gray-dark, #fff, 50%);
    border-color: darken(@brand-warning, 5%);
  }
}
.icon-large.icon-danger {
    border-color: lighten(@brand-danger, 30%);
  &:not([disabled]):hover, &:not([disabled]):focus, .open &:not([disabled]) {
    background: @brand-danger;
    color: contrast(@brand-danger, @gray-dark, #fff, 50%);
    border-color: darken(@brand-danger, 5%);
  }
}
