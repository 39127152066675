.keyframes(@name; @arguments) {
	@-moz-keyframes @name { @arguments(); }
	@-webkit-keyframes @name { @arguments(); }
	@keyframes @name { @arguments(); }
}

.type-size(@size) {
  font-size: unit(convert(@size, px), px);
}

.type-padding-top(@value) {
  padding-top: unit(convert(@value, px), px);
  padding-top: unit(convert((@value / 10), rem), rem);
}
.type-padding-bottom(@value) {
  padding-bottom: unit(convert(@value, px), px);
  padding-bottom: unit(convert((@value / 10), rem), rem);
}
.type-padding(@val-top, @val-bottom) {
  .type-padding-top(@val-top);
  .type-padding-bottom(@val-bottom);
}

.type-margin-top(@value) {
  margin-top: unit(convert(@value, px), px);
  margin-top: unit(convert((@value / 10), rem), rem);
}
.type-margin-bottom(@value) {
  margin-bottom: unit(convert(@value, px), px);
  margin-bottom: unit(convert((@value / 10), rem), rem);
}
.type-margin(@val-top, @val-bottom) {
  .type-margin-top(@val-top);
  .type-margin-bottom(@val-bottom);
}


.text-multiline-truncate(@lines, @line-height: @line-height-computed) {
  max-height: (@line-height * @lines);
  display: block;
  display: -webkit-box;
  -webkit-line-clamp: @lines;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
}

.text-sm {
  font-size: @font-size-small !important;
}
.text-md {
  font-size: @font-size-base !important;
}
.text-lg {
  font-size: @font-size-large !important;
}

.light {
  color: @brand-primary-light;
}

.placeholder(@color) {
  &::-webkit-input-placeholder {color: @color;}
  &:-moz-placeholder {color: @color;  }
  &::-moz-placeholder {color: @color;  }
  &:-ms-input-placeholder {  color: @color;  }
}

.placeholder(@color, @size, @line-height: @line-height-base) {
  &::-webkit-input-placeholder {color: @color; font-size: @size; line-height: @line-height ;}
  &:-moz-placeholder {color: @color; font-size: @size; line-height: @line-height;}
  &::-moz-placeholder {color: @color; font-size: @size; line-height: @line-height;}
  &:-ms-input-placeholder {color: @color; font-size: @size; line-height: @line-height;}
}

.column-rule(@args) {
  -webkit-column-rule: @args;
     -moz-column-rule: @args;
          column-rule: @args;
}

.column-break-inside(@args) {
	-webkit-column-break-inside: @args;
            page-break-inside: @args;
                 break-inside: @args;
}

.nowrap {
  white-space: nowrap;
}

.text-truncate() {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.border-radius(@radius) {
  -webkit-border-radius: @radius * 1px;
  border-radius: @radius * 1px;

  behavior: url(/styles/PIE.htc);
}

.big-number {
  .type-size(22);
  font-weight: 700;
  line-height: 1.0625;
  margin: 0;
}

.shade-border {
  border: @half-unit solid fade(@brand-primary, 10%);
}

.full-width {
  width: 100%;
}
.full-height {
  height: 100%;
}

.layout-compact {
  font-size: @font-size-small;
}

[role=button], [role=link] {
  span&, i& {
    color: @link-color;
    cursor: pointer;
    &:hover, &:focus {
      color: @link-hover-color;
    }
  }
}

.filter(@args) {
  -webkit-filter: @args;
  filter: @args;
}

//Blur effect
.blur-fallback(@value) when (@value = off) {
  &:before {
    display: none;
  }
}
.blur-fallback(@value) when (default()) {
  &:before {
    content: '';
    position: absolute;
    display: block;
    z-index: 99;
    top: 0; left: 0; bottom: 0; right: 0;
    background-color: fade(@brand-primary-light, 90%);
  }
}

.blur(@value) when (@value = off) {
  -webkit-filter: unset;
  filter: unset;
  .no-cssfilters & {
    .blur-fallback(off);
  }
}
.blur(@value) when (@value = on) {
  -webkit-filter: blur(3px);
  filter: blur(3px);
  .no-cssfilters & {
    .blur-fallback(on);
  }
}
.blur(@value) when (default()) {
  -webkit-filter: blur(@value);
  filter: blur(@value);
  .no-cssfilters & {
    .blur-fallback(on);
  }
}