.site-search-results {
	margin: 0 0 0 30px;
	  
	li {
		font-size: 20px;
		
		a {
			display: block;
			color: #444;
			
			&.url {
				font-size: 10px;
				color: #999;
			}
		}
	}
}