.expandable {

	&.closed {
		border-top: 1px solid #ddd;
	}

	button.expander {
		margin: -15px auto 20px auto;
		display: none;
	}
	
	.expandable-content {
		
	}
}