@import "bootstrap/less/tables.less";

// ----------------------------------------------------------------------------
// Tables
// ----------------------------------------------------------------------------

.search-domains-table {
  td {
    button.btn {
      font-size: 14px;
      span {
        @media screen and (max-width: @screen-sm) {
          display: none;
        }
      }
    }
  }
}

#search-domain-table .row {
  @media (max-width: @screen-xs-max) {
    margin-left: 0px;
    margin-right: 0px;
  }
}

.table {
  a:hover, a:focus {
    text-decoration: none;
  }
  background: #fff;
  .shade-border;
  border-collapse: separate;
  .type-size(14);
  .scrollable & {
    margin-bottom: 0;
  }
  thead > tr,
  tbody > tr {
    > th,
    > td {
      vertical-align: middle;
      line-height: 37px;
      padding-top: 7px;
    }
  }
  thead > tr > th {
    padding-top: 0;
    padding-bottom: 0;
    line-height: 31px;
    height: 36px;
    background-color: darken(@brand-primary-light, 3%);
    border-bottom-color: @brand-primary;
    .text-truncate();
  }
  tbody > tr > td {
    height: 55px;
    .align-top& {
      height: auto;
      line-height: 25px;
    }
  }

  tfoot > tr > td {
    background-color: darken(@brand-primary-light, 3%);
  }
  tfoot > tr:first-child td {
    border-top: 2px solid @brand-primary;
  }
}

.table .table-subhead {
  th {
    background-color: @table-bg-accent;
    border-bottom: none;
    font-size: 14px;
    height: 26px;
    padding-top: 3px;
  }
}

.table,
.table-wrapper {
  clear: both;
  .clearfix;
  .checkcol {
    width: 40px;
    min-width: 40px !important;
    label {
      margin: 0;
    }
  }
  .thumbcol {
    max-width: 50px;
    img {
      max-width: 50px;
    }
  }
}

.td-link {
  padding: 0 !important;
  cursor: pointer;
  > a:first-child {
    display: block;
    padding: @table-cell-padding;
    .table-condensed & {
      padding: @table-condensed-cell-padding;
    }
    .table-subhead & {
      padding-top: 4px;
      height: 26px;
    }
  }
  &:hover,
  &:focus {
    background-color: darken(@table-bg-accent, 10%);
  }
}

td.domain {
  word-break: break-all;
}

.table-meta-row {
  background-color: @table-bg-accent;
}

.collapsed .table {
  .table-condensed;
}

@media screen and (max-width: @screen-sm-max) {
  .expanded .table {
    .table-condensed;
  }
  .expand-toggle {
    display: none;
  }
  .expanded-visible {
    display: none;
  }
  .table-responsive {
    overflow-x: auto;
  }
}

@media screen and (min-width: @screen-sm) {
  .expanded-visible {
    .collapsed & {
      display: none;
    }
  }
}
.tr-highlight(@color) when (@color = none) {
  table.table > tbody > & {
    td,
    th {
      color: @text-color;
      background-color: @table-bg-accent;
      border-top-color: @table-border-color;
    }
    &:nth-child(2n) {
      td,
      th {
        background-color: @table-bg;
      }
    }
  }
}
.tr-highlight(@color) when (default()) {
  table.table > tbody > & {
    td,
    th {
      color: darken(@color, 12%);
      background-color: lighten(@color, 35%);
      border-top-color: lighten(@color, 25%);
    }
    &.tr-flash {
      td,
      th {
        .animation(trFadeOut 1s linear 2s 1 forwards);
      }
    }
  }
}

.keyframes(trFadeOut; {
  to {
      color: @text-color;
      background-color: @table-bg-accent;
      border-top-color: @table-border-color;
    }
});

.tr-default {
  .tr-highlight(none);
}
.tr-danger {
  .tr-highlight(@brand-danger);
}
.tr-warning {
  .tr-highlight(@brand-warning);
}
.tr-success {
  .tr-highlight(@brand-success);
}
.tr-info {
  .tr-highlight(@brand-info);
}
.tr-primary {
  .tr-highlight(@brand-primary);
}

div.table-wrapper {
  .shade-border;
  .table {
    border: none;
  }
}

.table-condensed {
  thead > tr,
  tbody > tr {
    > th,
    > td {
      vertical-align: middle;
      line-height: 1.4;
      padding: 7px 7px 7px;
    }
  }
  tbody > tr > td {
    vertical-align: middle;
    height: 48px;
  }
  td {
    .btn-group {
      font-size: 0;
      white-space: nowrap;
      > .btn,
      > .btn-group {
        float: none;
        display: inline-block;
      }
      > .tooltip,
      > .popover {
        .type-size(12);
      }
    }
    .btn {
      .btn-sm;
      padding: 8px 10px;
      margin: -8px 0 -8px;
    }
    .btn.btn-xs {
      padding: 2px 6px;
      font-size: 9px;
      margin: -8px 0 -8px;
    }
    .dropdown-menu .btn {
      margin: 0;
      padding-bottom: 7px;
      line-height: 1.1;
    }
  }
  th {
    .btn {
      //font-size: 14px;
      //padding: 6px 12px;
      margin: -7px 0;
    }
  }
}

th.sortable {
  a {
    padding-right: 16px;
    display: block;
    &:before {
      .fa;
      content: @fa-var-sort;
      float: right;
      font-size: 10px;

      margin-right: -16px;
      color: @gray-lighter;
      .table-condensed & {
        margin-top: 3px;
      }
      margin-top: 12px;
    }
    &:hover:before {
      color: @gray-darker !important;
    }
  }
  &.sorted a:before {
    color: @gray-darker !important;
  }
  &.sorted.ascending a:before {
    content: @fa-var-sort-up;
  }
  &.sorted.descending a:before {
    content: @fa-var-sort-down;
  }
}

.empty-row {
  //text-align: center;
}

tr[data-toggle="collapse"] {
  cursor: pointer;
}
.expandable-info {
  td {
    padding: 0 !important;
    border-top: none !important;
    > div {
      > .expandable-info-body {
        padding: 15px;
      }
    }
  }
}
.space-left {
  margin-left: @unit;
}
.btn-mono {
  .btn-link;
  width: 1.4125em;
  padding-left: 0 !important;
  padding-right: 0 !important;
  text-align: center;
}

.table-container.limit-height {
  position: relative;
  padding-bottom: 37px;
  margin-bottom: 30px;
  .table-scrollable {
    max-height: 391px;
    overflow-y: auto;
    table {
      position: static;
      margin-bottom: 0;
    }
    tfoot {
      display: table;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
    }
  }
}

.manage-table {
  th,
  td {
    &:last-child:not(:first-child) {
      text-align: right;
      white-space: nowrap;
    }
  }
}

.portfolio-table {
  &:extend(.manage-table all);
  th,
  td {
    &:last-child:not(:first-child) {
      padding-right: 0;
      .btn-group {
        margin: 0;
        padding: 0;
      }
    }
  }
  .text-error {
    em {
      padding-right: 10px;
    }
  }
}

.cart-summary-table {
  &:extend(.portfolio-table all);
  border: none;
  margin-bottom: 0;
  .label {
    font-size: 14px;
  }
}

.search-domains-table {
  &:extend(.portfolio-table all);
  border: none;
  margin-bottom: 0;
  th,
  td {
    &:last-child:not(:first-child) {
      .btn {
        width: 80px;
      }
      @media (min-width: @screen-sm) {
        width: 140px;
        .btn {
          min-width: 105px;
          margin-right: 4px;
        }
      }
    }
    .btn.whois {
      min-width: 0 !important;
    }
  }
}

.dns-table {
  &:extend(.manage-table all);
}

.table-more-toggle {
  && {
    padding: 0;
    border-top: none !important;
    a {
      display: block;
      padding: 7px;
      background-color: @table-bg-accent;
      font-weight: bold;
      color: @gray-darker;
      &:hover {
        background-color: darken(@table-bg-accent, 5%);
      }
      &:focus {
        background-color: @table-bg-accent;
        color: @gray-darker;
        text-decoration: none;
        &:hover {
          background-color: darken(@table-bg-accent, 5%);
          color: @link-color;
          text-decoration: none;
        }
      }
      i.pull-right {
        position: relative;
        top: 3px;
        right: 6px;
      }
    }
  }
}

//Table filter group
.table-filter-group {
  background-color: @brand-primary-light;
  border: 1px solid @gray-light;
  padding: 4px;
  margin-bottom: @tri-unit;
  .modal-dialog & {
    margin-bottom: 0;
  }
  p {
    margin: 2px 3px;
  }
}
.table-filter {
  margin-bottom: 0;
  padding: @unit;
  padding-bottom: @half-unit;
}

.table-filter-table {
  clear: both;
  margin-left: -4px;
  margin-right: -4px;
  margin-bottom: 0;
  table,
  .table-wrapper {
    margin-bottom: 0;
  }
  td:last-child {
    padding-right: 0;
  }
  .container-loading {
    &:before {
      margin-top: 200px;
    }
  }
}

.tld-table {
  .table-responsive{
    .container-loading {
      min-height: 350px;
    }
  }
}

//Empty table notice
.table-empty {
  position: relative;
  height: 350px !important;
}

.tld-table {
  background-color: #fff;
  font-size: 12px;



  tr:nth-child(odd) {
    background-color: @brand-grey;
    border: none;
  }

  tr {
    border: none;
  
  }
  td:first-child {
    text-align: left;
  }
  td {
    border: none;
    text-align: right;
    min-width: 130px;
    height: 30px !important;
    line-height: 30px !important;
    &:last-child:not(:first-child) {
      padding-right: 12px;
    }
  }
  th:first-child {
    text-align: left;
  }
  th {
    background-color: #fff;
    border: none;
    text-align: right;
  }
  .table {
    border: none;
  }
  .table thead > tr > th {
    border: none;
    background-color: #fff;
  }
  .table > tbody > tr > td {
    border: none;
  }
  &.table-filter-group {
    border: none;
  }
  td,tr {
    font-size: 1em;
  }
}

.table-header {
  padding-bottom: 24px;
}

.search-domains-table {

  tbody {
    tr {
      td {
        padding-top: 12px;
        padding-bottom: 12px;
      }
    }
  }
}