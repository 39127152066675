.widget.domain-generator-results {
	padding: 0;
	border: none;
	background: transparent;
	
	.col.first {
		box-sizing: border-box;
		width: 100%;
		margin-right: -200px;
		padding-right: 215px;
	}
	
	.col.last {
		width: 200px;
	}
	
	.search-button {
		width: 100%;
	}
}