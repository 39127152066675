@import "bootstrap/less/input-groups.less";

.input-inner {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
  height: 100%;
  &.btn, .btn {
    .btn-link;
    .transition(background-color .2s);
    &:hover, &:focus {
      text-decoration: none;
      background-color: fade(#000, 10%);
    }
  }
}

.input-prepend {
  position: absolute;
  top: 50%;
  left: 10px;
  .translate(0, -50%);
  z-index: 10;
  display: block;
  color: @input-color;
  + .form-control {
    padding-left: 35px !important;
  }
}

.input-group-fw {
  width: 100%;
}

.has-feedback .form-control {
  padding-right: 31px;
}

.form-control-feedback {
  top: 0;
  z-index: 3;
  &.btn {
    .btn-link;
    width: 31px;
    padding-left: 3px;
    padding-right: 3px;
    color: @gray-dark;
    line-height: inherit;
  }
}

.input-group-addon {
  font-size: 12px;
}

.input-static {
  .input-group-addon;
  display: inline-block;
  border-left-width: 1px;
  border-right-width: 1px; 
  width: auto;
  white-space: nowrap;
  .button-size(@padding-base-vertical; @padding-base-horizontal; @font-size-base; @line-height-base; @border-radius-base);
}