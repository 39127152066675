.sitemap {
	.navigation {
		list-style: none;
		
		@media (min-width: @screen-sm) {
      .column-count(2);
		}
		@media (min-width: @screen-md) {
      .column-count(3);
		}
    .column-rule(1px outset @gray-lighter);
		
		ul {
			list-style: none;
		}
		li {
  		padding-left: 20px;
      .column-break-inside(avoid);
		}
		a {
  		display: block;
  		padding: 5px;
  		&:hover, &:focus {
    		background-color: @table-bg-accent;
  		}
		}
		
		> li {
  		padding-left: 0;
			margin-bottom: 30px;
			> a {
				.heading-font;
				font-size: @font-size-h4;
				color: @headings-color;
				&:hover, &:focus {
  				color: @link-hover-color;
				}
			}
		}
  }
}