.checkout-footer {
  margin-bottom: @tri-unit;
  .checkout-total {
  text-align: right;
    margin-bottom: @unit;
  }
}

/*
.cart {
	&.empty {
		.empty-cart-message {
			display: block;
		}
	}
	
	&.not-empty {
		table {
			display: table;
		}
		
		.cart-footer {
			display: block;
		}
	}
	
	.empty-cart-message {
		display: none;
		margin: 0;
	}
	
	table {
		display: none;
		width: 100%;
		
		thead tr th {
			padding: 5px 20px;
			border-bottom: 1px solid #eee;
			font-weight: bold;
			color: #009ef9;
			font-size: 16px;
			white-space: nowrap;
			
			&.field-name {
				padding-left: 20px;
				text-align: left;
			}
			
			&.field-price {
				//width: 130px;
				text-align: right;
			}
			
			&.field-years {
				//width: 100px;
				text-align: center;
			}
			
			&.field-total-price {
				//width: 150px;
				text-align: right;
			}
			
			&.field-remove {
				width: 40px;
				padding-right: 20px;
				text-align: center;
			}
		}
		
		tbody tr {
			&.template {
				display: none;
			}
			
			td {
				padding: 5px 20px;
				border-bottom: 1px solid #eee;
				vertical-align: middle;
				font-size: 14px;
				white-space: nowrap;
				
				&.field-name {
					padding-left: 20px;
					font-weight: bold;
				}
				
				&.field-price {
					text-align: right;
				}
				
				&.field-years {
					text-align: center;
					
					select {
						height: 24px;
						width: auto;
					}
				}
				
				&.field-total-price {
					text-align: right;
					font-weight: bold;
				}
				
				&.field-remove {
					padding: 5px 20px 5px 5px;
				}
			}
		}
		
		tfoot tr.total td {
			padding: 50px 20px 30px 20px;
			text-align: right;
			font-weight: bold;
			
			.total-price {
				margin: 0;
				font-size: 20px;
			}
			
			.vat {
				margin: 0;
				font-size: 12px;
				color: #999;
				font-weight: normal;
			}
		}
	}
	
	.cart-footer {
		display: none;
		padding: 20px;
		
		.button {
			float: right;
		}
	}
}
*/
