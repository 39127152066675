@import "bootstrap/less/popovers.less";

.popover-alert {
	top: 100%; 
	display: block; 
	margin-top: 0px; 
	width: 240px; 
	max-width: 73vw;
	box-shadow: 0 2px 1px rgba(0, 0, 0, 0.2);
	color: @gray-dark;
	p {
  	font-size: @font-size-base;
	}
	.popover-title h3 {
	  font-size: inherit;
	  margin: 2px 0;
	}
	.popover-content {
		font-size: @font-size-small;
		line-height: @line-height-base;
	}
}

.popover-alert {
  &.forgot-password {
    left: auto;
    right: 0;
    width: 308px;
    .arrow {
      right: 20px;
      left: auto; 
  	}
  	.form-control {.input-sm;}
  	.btn {.btn-sm;}
  	.input-group {
    	margin-bottom: 5px;
  	}
  	@media (max-width: @screen-xs-max) {
      display: none;
    }
  }
  &.signin {
    margin-top: 5px;
    left: 43px;
    @media (max-width: @screen-xs-max) {
      display: none;
    }
    .arrow {
      left: 20px;
      right: auto; 
  	}
  }
  
  &.cart-confirmation {
  	right:0; 
  	left: auto; 
  	.arrow {
  		 left: auto;
  		 right: 10px; 
  		 @media (min-width: @screen-sm) {
      	right: 16px; 
    	 }
    	 @media (min-width: @screen-md) {
      	right: 28px; 
      	.compact & {
        	right: 16px; 
      	}
    	 }
  	}
  }
}
