.label {
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.5;
  font-weight: normal;
  border: 1px solid transparent;
}
.label-block {
  .btn-block;
}

.label-sm {
  padding: 2px 5px;
  font-size: @font-size-small;
}

.label-inverse {
  color: #fff;
  background-color: @gray-darker;
}

.label-info,
.label-owner-transfer,
.label-mx {
  background-color: lighten(desaturate(@brand-info,10%),15%);
}
.label-primary,
.label-new-domain,
.label-a {
  background-color: lighten(desaturate(@brand-primary,30%),20%);
}
.label-danger,
.label-whois-protection,
.label-srv {
  background-color: lighten(desaturate(@brand-danger,20%),20%);
}
.label-success,
.label-renewal,
.label-txt {
  background-color: lighten(desaturate(@brand-success,20%),20%);
}
.label-warning,
.label-transfer,
.label-cname {
  background-color: lighten(desaturate(@brand-warning,20%),5%);
}
