.grid-more-btn {
    .btn {
        display: block;
        width: 100%;
        border-width: 0;
        border-bottom: 7px solid @gray-lighter;
        height: 10px;
        .grid-more-btn-label {
            &:hover,
            &:focus {
                outline: none; // border-bottom-color: @panel-info-border;
                border-bottom-color: @complement-color-lighter;
                .grid-more-btn-label {
                    text-decoration: underline;
                }
            }
        }
    }
    .grid-more-btn-label {
        position: relative;
        background-color: @gray-lightest;
        display: inline-block;
        padding: 0 40px;
        z-index: 50;
    }
    panel-list & {
        padding-left: @grid-padding;
        padding-right: @grid-padding;
    }
}