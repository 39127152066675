nav.mobile {
	border-top:1px solid #000;
	display: none;
	z-index: 9999;
	
	ul {
		list-style-type:none;
		padding:0px;
		margin:0px;
		
		li {
			display: block;
			
			a {
				display: block;
				padding:10px 0px;
				opacity: .8;
				border-bottom:1px solid #333;
				border-top:1px solid #666;
				font-family: 'Ave45', arial, sans-serif;
				color:#ffffff;
				text-decoration:none;
			}
			
			&.first a {
				padding-top:15px;
				border-top:none;
			}
			
			&.last a {
				padding-bottom:15px;
				border-none:none;
			}
		}
	}
}