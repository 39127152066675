nav.user {
	border-right: 1px solid #1e9de7;
	border-left: 1px solid #3bb3f9;
	font-size: 16px;
	
	ul {
		list-style: none;
		margin: 0;
		padding: 0;
		
		li {
			float: left;
			position: relative;
			padding: 0px;
			height: 80px;
			border-right: 1px solid #3bb3f9;
			border-left: 1px solid #1e9de7;
			font-size: 11px;
			line-height: 18px;
			
			span,
			a {
				display: block;
				padding: 42px 24px 20px 24px;
				line-height: 18px;
				opacity: .8;
				color: #ffffff;
				text-decoration: none;
				text-shadow: 0px 1px 0px #007ac0;
				cursor: pointer;
				
				&:hover {
					opacity: 1.0;
				}
			}

			&.mobile-nav {
				display: none;
				
				span {
					opacity: 1.0;
					background: url('/assets/images/nav-icon.png') no-repeat center 18px;
					text-indent: -9999px;
				}
			}
			
			&.cart {
				a {
					background: url('/assets/images/cart-icon.png') no-repeat center 18px;
					
					.badge {
						display: none;
						position: absolute;
						top: 15%;
						left: 30%;
						padding: 0;
						opacity: 1;
						border: 1px solid #0088d6;
						border-radius: 50%;
						background-color: #fff;
						color: #0088d6;
						text-align: center;
						line-height: 14px;
						font-size: 10px;
						text-shadow: none;
					}
				}
			}
			
			&.login {
				span {
					background: url('/assets/images/key-icon.png') no-repeat center 18px;
				}
				
				&.toggled span {
					background: #fff url('/assets/images/key-icon_dark.png') no-repeat center 18px;
					color: #999;
					text-shadow: none;
					opacity: 1;
				}
			}
		}
	}
}