ol.terms-list {
	margin: 0;
	list-style-type: none;
	list-style: none;
	counter-reset: item;
	
	> li {
		&:before {
			padding-right: 10px;
			content: counters(item, ".") " ";
			counter-increment: item;
			font-family: Asap, Roboto, Arial, sans-serif;
			font-weight: bold;
			font-style: italic;
			margin-bottom: 15px;
			padding-top: 20px;
			font-size: 16px;
		}
		
		h2 {
			display: inline;
		}
		
		p {
			display: inline;
		}
		
		> ol {
			margin: 10px 0 0 0;
			list-style-type: none;
			list-style: none;
			counter-reset: item;
			
			> li {
				margin-bottom: 10px;
				&:before {
					padding-right: 10px;
					content: counters(item, ".") " ";
					counter-increment: item;
				}
				
				> ol {
					margin: 10px 0 0 16px;
					list-style-type: upper-alpha;
					counter-reset: item;
				}
			}
		}
	}
}