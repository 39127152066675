.widget.domain-map {
	.choose-tld {
		float: left;
		width: 48%;
		margin: 0;
	}
	
	.choose-country {
		float: right;
		width: 49%;
		margin: 0;
	}
}