.select-category {
  position: relative;
  width: 100%;
  text-align: right;
  font-size: 1.2em;
  @media (max-width: @screen-sm-max) {
    padding-top: 1em;
    text-align: left;
  }
  button {
    margin-left: 0.5em;
    background-color: lighten(@gray-lighter, 2%);
    color: @gray-dark;
    border: none;
    padding: 8px;
    font-weight: 500;
    font-style: italic;
    font-family: @headings-font-family;
    &:hover,
    &:active,
    &:visited,
    &:focus {
      background-color: @gray-lighter;
      border: none;
      color: @gray-darker;
    }
    @media (max-width: @screen-sm-max) {
      display: block;
      margin-left: 0;
    }
    .caret {
      color: @brand-red;
      font-size: 14px;
    }
  }
  ul {
    list-style: none;
    padding-left: 0px;
    li {
      cursor: pointer;
      &:hover {
        background-color: @gray-lighter;
      }
    }
  }
}

.open .category-sub-menu {
  display: block;
  overflow-y: auto;
  max-height: 360px;
}
.category-sub-menu {
  display: none;
  position: absolute;
  z-index: 1000;
  background-color: #fff;
  right: 0;
  text-align: left;
  border: 1px solid @gray-lighter;
  @media (max-width: @screen-sm-max) {
    right: auto;
    left: 0;
  }
  li {
    padding: 12px 14px;
  }
}

.select-component {
  z-index: 1000;
  background-color: #f0f0f2;
  padding: 8px;
  margin-left: 0.5em;
  color: #666;
  border: none;
  padding: 8px;
  font-weight: 500;
  font-style: italic;
  font-family: 'Asap', 'Helvetica Neue', sans-serif;
  padding-right: 25px;
  -moz-appearance:none; /* Firefox */
  -webkit-appearance:none; /* Safari and Chrome */
  appearance:none;
}
.select-wrapper {
  display: inline-block;
  position: relative;
}

.select-wrapper::after {
  position: absolute;
  top: 20px;
  right: 10px;
  border-top: 4px dashed;
  border-top: 4px solid\9;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
  content: '';
  color: @brand-red;
}