input[type="text"],
input[type="email"],
input[type="url"],
input[type="password"],
input[type="search"],
textarea {
  &::-webkit-input-placeholder {color: @gray;}
  &:-moz-placeholder {color: @gray;  }
  &::-moz-placeholder {color: @gray;  }
  &:-ms-input-placeholder {  color: @gray;  }
}

.form-item {
	margin-bottom: 15px;
	
	label {
		display: block;
		font-weight: bold;
		font-size: 14px;
		line-height: 22px;
		color: @gray-dark;
	}
	
	ul { // Error messages
		color: #f00;
	}
	
	&.form-item-checkbox {
		label {
			font-weight: normal;
			font-size: 12px;
		}
		
		input {
			vertical-align: middle;
			margin-right: 10px;
		}
	}
	
	&.required {
		label:after {
			content: ' *';
			color: @brand-danger;
		}
	}
	
	&.compound {
		position: relative;
		height: 40px;
		
		input {
			height: 100%;
			padding-right: 40px;
			
			&:hover + .buttons {
				border-color: #0077b2;
			}
			
			&:focus + .buttons {
				border-color: #0077b2;
			}
		}
		
		.buttons {
			box-sizing: border-box;
			position: absolute;
			top: 0px;
			right: 0px;
			height: 100%;
			
			&:hover + input {
				border-color: #0077b2;
			}
			
			&:focus + .input {
				border-color: #0077b2;
			}
			
			.button {
				float: right;
				height: 100%;
				width: 40px;
				padding: 0 10px;
				background-color: transparent;
				border: none;
				box-shadow: none;
				
				&:active {
				}
				
				&:hover {
					background-color: rgba(0, 0, 0, 0.03);
				}
				
				.button-inner {
					font-size: 20px;
					color: @gray;
				}
			}
		}
	}
}

.form-table {
	width: 100%;
}
