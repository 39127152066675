.table-pagination {
  padding-top: 12px;
  border-top: 2px solid @gray-light;
  position: relative;
  right: 4px;
  @media screen and (max-width: @screen-sm-max) { 
    
  }
  .pagination-buttons {
    position: relative;
    padding-top: 12px;
    .prev-button,
    .next-button {
      background-color: none;
      &:hover,
      &:focus {
        background-color: #fff;
        outline: none;
        border: none;
        opacity: 0.8;
      }
      @media screen and (max-width: @screen-sm-max) {
        span {
          display: none;
        }
        i {
          font-size: 24px;
          margin-left: 2px;
          margin-right: 2px;
        }
      }
    }
    button, .pagination-page {
      &.mobile{
        @media screen and (min-width: @screen-sm) {
          display: none;
        }
      }
      &.desktop{
        @media screen and (max-width: @screen-sm) {
          display: none;
        }
      }
      color: @complement-color-dark;
      font-size: 14px;
      font-weight: 500;
      border: none;
      background: #fff;

      &.pagination-pages {
        width: 32px;
        padding-right: 0px;
        padding-left: 0px;
        padding-top: 7px;
        font-size: 1em;
        @media screen and (max-width: @screen-sm-max) {
          min-width: auto;
          padding-top: 8px;
        }
        @media screen and (max-width: @screen-sm) {
          min-width: 80px;
          padding-top: 3px;
        }
      }
      i {
        font-size: 16px;
      }
      &:hover,
      &:focus {
        outline: none;
        opacity: 0.8;
      }
    }
    .active {
      border-bottom: 2px solid @complement-color-dark;
      box-shadow: none;
      background-color: @brand-grey;
    }
    span {
      padding: 0 6px 0 6px;
    }
    .disabled, .disabled:focus {
      opacity: 0.5;
      cursor: none;
      pointer-events: none;
    }
    
  }
}

span {
  &.static {
    font-size: 14px;
    font-weight: 500;
    border: none;
    padding: 10px 12px;
    background-color: @brand-grey;
    position: absolute;
    top: -12px;
    right: 0;
  }
}
