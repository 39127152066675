@import "bootstrap/less/buttons.less";

.btn-md {
  .button-size(@padding-base-vertical; @padding-base-horizontal; @font-size-base; @line-height-base; @border-radius-base);
}

.btn.active {
  box-shadow: none;
}

// Toggle states
.btn.toggle-on.active,
.open .btn.toggle-on {
  background-color: lighten(@brand-success,20%);
  &:hover, &:focus {
    background-color: lighten(@brand-success,10%);
  }
}
.btn.toggle-off.active,
.btn.toggle-danger.active,
.open .btn.toggle-off,
.open .btn.toggle-danger {
  background-color: lighten(@brand-danger,20%);
  &:hover, &:focus {
    background-color: lighten(@brand-danger,10%);
  }
}
.btn.toggle-selected.active, 
.open .btn.toggle-selected {
  background-color: lighten(@brand-primary,40%);
  &:hover, &:focus {
    background-color: lighten(@brand-primary,35%);
  }
}
.btn.toggle-primary.active, 
.open .btn.toggle-primary {
  background-color: @brand-primary; color: #fff;
  &:hover, &:focus {
    background-color: darken(@brand-primary,10%);
  }
}
.btn.toggle-alt.active,
.open .btn.toggle-alt {
  background-color: lighten(@brand-warning,20%);
  &:hover, &:focus {
    background-color: lighten(@brand-warning,10%);
  }
}


.btn-group-inline { 
  padding-left: 1px;
  > .btn,
  > .btn-group,
  > .input-static {
    float: none !important;
    display: inline-block !important; 
    margin-left: -1px;
  }
  font-size: 0; //Removes spaces between inline elements
}

button-select {
  dd &,
  .list-data & {
    .dropdown-toggle {
      .btn-sm;
    }
  }
  &.dropdown-menu-right {
    right: auto;
    .dropdown-menu {
      .dropdown-menu-right;
    }
  }
}
  
.btn-group-addon {
  //use inside btn-group
  position: absolute;
  left: 5px;
  top: 5px;
  z-index: 3;
  + .btn {
    padding-left: 30px !important;
  }
}

.btn-link {
  .list-row &,
  .table td & {
      position: relative;
      top: -2px;
  }
}

.btn-bar {
  .btn-block;
  text-align: left;
  padding-left: @padding-base-horizontal;
  padding-right: 22px;
  .btn-bar-label {
    .text-truncate();
    display: inline-block;
    max-width: 100%;
  }
  .caret {
    position: absolute;
    top: 6px;
    right: 12px;
    float: right;
    margin-top: 7px;
  }
}

.btn-bar-group {
  width: 100%;
  button-select& {
    display: block;
    > .btn-group {
      width: 100%;
      > .btn {
        .btn-bar;
      }
    }
  }
}

.btn-multiline {
  white-space: normal;
}


.btn-default {
  &.zeroclipboard-is-hover {
    background-color: darken(@btn-default-bg, 10%);
    border-color: darken(@btn-default-border, 12%);
  }
}

.btn-mix {
    .button-variant(@btn-success-color; lighten(mix(@brand-success,@brand-danger),10%); mix(@brand-success,@brand-danger));
}


.table-filter-group {
  .pagination {
    display: block;
    margin: 0 auto;
  }
}

pagination {
  position: relative;
  display: block;
}

.paging-location {
  position: absolute;
  right: 0;
  top: 0;
}

.pagination-buttons {
  button {
    border: 1px solid @gray-light;
    padding: 6px 12px;
    background-color: @brand-primary-light;
    float: left;
    @media (max-width: @screen-xs-max) {
      padding: 4px 6px;
    }
    i {
      font-size: 16px;
    } 
    &:focus {
      outline: none
    }
    &:hover {
      color: @gray-dark;
      background-color: #c0d3dd;
      border-color: #adadad;
    }
  }
  .active {
    color: @gray-dark;
    background-color: #c0d3dd;
    border-color: #adadad;
    box-shadow: none;
    outline: none;
  }
  span {
    padding:0 6px 0 6px;
  }

}

.btn-space {
  margin-right: 5px;
  margin-bottom: 5px;
}

button:disabled{
  pointer-events: none;
}

.btn-lg {
  min-width: 135px;
  @media (max-width: @screen-xs-max) {
    min-width: 100px;
  }
}