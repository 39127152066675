.dropdown .dropdown-header {
	padding: 5px 0 5px;
	border-top: 1px solid #999;
}

.dropdown.closed .dropdown-header {
	cursor: pointer;
}

.dropdown.closed .open-dropdown {
	display: block;
	float: right;
}

.dropdown.open .open-dropdown {
	display: none;
}

.dropdown .dropdown-header .icon:before {
	display: inline-block;
	width: 1em;
	height: 1em;
	font-family: FontAwesome;
	color: #aaa;
}

.dropdown.closed .dropdown-header .icon:before {
	content: "\f0da";
}

.dropdown.open .dropdown-header .icon:before {
	content: "\f0d7";
}

.dropdown.closed .dropdown-content {
	display: none;
}

.dropdown.open .dropdown-content {
	display: block;
}
