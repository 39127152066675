.popup {
	display: none;
	position: absolute;
	z-index: 8888;
	box-sizing: border-box;
	padding: 15px;
	border: 1px solid #999;
	background-color: @gray-lightest;
	
	&.active {
		box-shadow: 0px 3px 2px rgba(0, 0, 0, .3);
	}
	
	a {
		color: @link-color;
		
		&:hover {
			color: @link-hover-color;
		}
	}
}