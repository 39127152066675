//Template mixin for promo circles
.search-result-promo-variant(@color, @hover-color, @icon-var, @hover-icon-var: @icon-var) {
  border-color: @color;
  color: @color;
  .domain-info {
    background-color: @color;
  }
  &:before {
    background-color: @color;
    content: @icon-var;
  }
  &.selected, &:hover {
    border-color: @hover-color;
    color: @hover-color;
    &:before {
      background-color: @hover-color;
      content: @hover-icon-var;
    }
  }
  &:hover {
    box-shadow: 0 -14px 0 fade(@hover-color, 50%) inset, 0 14px 0 fade(@hover-color, 50%) inset;
    .domain-info {
      background-color: @hover-color;
    }
  }
  &.selected {
    border-color: @hover-color;
    //background: darken(@hover-color, 10%);
    //color: #fff;
    // &:before {
    //   background: darken(@hover-color, 10%);
    // }
    &,
    &:hover {
      .domain-info {
        background-color: @hover-color;
      }
    }
    &:hover {
      box-shadow: 0 -14px 0 fade(@brand-danger, 50%) inset, 0 14px 0 fade(@brand-danger, 50%) inset;
      border-color: @brand-danger;
      color: @brand-danger;
      &:before {
        background-color: @brand-danger;
        content: @fa-var-times;
      }
      .domain-info {
        background-color: @brand-danger;
      }
    }
  }
}



.search-results {
  margin-bottom: 70px;
  @media (max-width: @screen-xs-max) {
    margin-bottom: 0px;
  }
}

.search-result-single {
  padding: 20px;
  .clearfix;
  h1 {
   float: left;
   margin-bottom: 0;
  }
  &-controls {
    float: right;
    .whois {
      @media (max-width: @screen-sm-max) {
        display: block;
        float: none;
        margin: 0 auto 10px;
        .btn {
          float: none;
          .btn-sm;
        }
        .tooltip {
          display: none !important;
        }
      }
      
    }
  }
  .price {
    padding: 0 15px;
    vertical-align: middle;
    font-size: @font-size-large;
  }
  @media (max-width: @screen-xs-max) {
    h1 {
     font-size: ( @font-size-large + 5 );
    }
  }
  @media (max-width: @screen-sm-max) {
   h1 {
     text-align: center;
     float: none;
   }
   &-controls {
     float: none;
     text-align: center;
   }
   .price {
     padding: 15px 0;
     display: block;
   }
   .campaign {
     .price {
       padding-top: 0;
     }
     .price-original {
       padding-top: 15px;
     }
   }
  }
}

.search-result-promos {
  margin: 50px 0 0;
  text-align: center;
  @media (max-width: @screen-xs-max) {
    display: none;
  }
}

.search-result-promo {
  position: relative;
  width: 119px;
  height: 119px;
  margin: 0 (@grid-padding / 2);
  display: inline-block;
  background-color: #fff;
  border: 1px solid @gray;
  border-top-width: 7px;
  border-radius: 100%;
  outline: none;
  .transition(~'box-shadow .2s, border-color .2s');
  @media (max-width: @screen-xs-max) {
    .scale(0.7);
    margin: -10px -14px;
  }
  &:before {
    position: absolute;
    display: block;
    content: '';
    top: -7px;
    right: 0;
    width: 30px;
    height: 30px;
    background-color: @gray;
    .transition(background-color .1s);
    border-radius: 100%;
    padding: 6px 7px;
    .fa;
    color: #fff;
    font-size: 17px;
  }
  &.loading {
    .glyph-spinner;
  }
  .domain-info {
    position: absolute;
    bottom: -10px;
    left: 50%;
    font-size: (@font-size-small - 1);
    background-color: @gray;
    color: #fff;
    padding: 0 6px;
    border-radius: 2px;
    .translate(-50%,0);
    white-space: nowrap;
    @media screen and (min-width: @screen-md-min){
      opacity: 0;
    }
    .transition(opacity .1s);
  }
  &.selected, &:hover, .touchevents & {
    .domain-info {
     opacity: 1;
    }
  }
  &-controls {
    position: absolute;
    top: 47%;
    left: 50%;
    width: 100%;
    .translate(-50%, -50%);
    overflow: hidden;
    .sld {
      display: none;
    }
    .tld {
      font-size: ( @font-size-large + 4 ) ;
    }
    .price {
      display: block;
      line-height: 1;
      font-size: @font-size-base;
    }
  }
  
  
  &.available {
    .search-result-promo-variant(
      darken(@brand-success, 5%),
      @brand-success,
      @fa-var-check,
      @fa-var-shopping-cart
    );
  }
  &.owned {
    .search-result-promo-variant(
      darken(@brand-primary, 5%),
      @brand-primary,
      @fa-var-briefcase
    );
  }
  &.unavailable {
    .search-result-promo-variant(
      darken(@brand-danger, 5%),
      darken(@brand-warning, 5%),
      @fa-var-times,
      @fa-var-exchange
    );
  }
  &.error {
    .search-result-promo-variant(
      @gray,
      @gray,
      @fa-var-exclamation,
      @fa-var-exclamation
    );
    .domain-info {
      opacity: 1;
    }
  }
  &.promo-loading {
    border-color: transparent;
    &:before {
      display: none;
    }
    &:after {
      display: block;
      .fa-spin;
      content: '';
      position: absolute;
      top: -7px;
      left: -7px;
      bottom: -7px;
      right: -7px;
      border-radius: 100%;
      box-shadow: 0 -10px 0 @gray-light inset;
    }
  }
  // &.selected:hover {
  //   box-shadow: 0 -14px 0 fade(@brand-danger, 50%) inset, 0 14px 0 fade(@brand-danger, 50%) inset;
  // }
  &[disabled]:hover {
    box-shadow: none;
  }
}


.campaign {
  .price {
    font-weight: bold;
    color: @brand-warning;
  }
  .price-original {
    font-size: @font-size-small;
    text-decoration: line-through;
    font-weight: normal !important;
    color: inherit !important;
    padding: 0;
  }
  &.selected .price {
    color: lighten(@brand-warning, 5%);
  }
}