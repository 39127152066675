#content {
  background: @gray-lightest;
	padding: 200px 0 100px 0;
	@media (max-width: @screen-xs-max) {
  	padding-top: 150px;
	}
	
	&.loading-lg {
  	background: @gray-darker;
	}

/**
	.inner {
		display: block;
		margin: 0 auto;
		overflow: visible;
		padding: 0 @grid-padding;
		.clearfix;
		@media (min-width: @screen-sm) {
  		width: @container-sm;
  		padding: 0;
		}
		@media (min-width: @screen-md) {
  		width: @container-md;
		}
	}
**/
	
	main {
		section.content {
			margin-bottom: 20px;
		}
		
		nav ul {
			margin-left: 20px;
		}
	}
	
	&.with-sidebar {
		main {
			width: 700px;
			float: right;
		}
		
		&.sidebar-right {
			main {
				float: left;
			}
			
			.sidebar {
				float: right;
			}
		}
	}
	
	&.with-two-sidebars {
		main {
			float: left;
			width: 386px;
		}
		
		.sidebar.left {
			float: left;
			margin-right: 30px;
		}
		
		.sidebar.right {
			float: right;
		}
	}
	
	.sidebar {
		float: left;
		width: 257px;
		
		&.right {
			width: 243px;
		}
	}
}