@import "header/header-mixins.less";
@import "header/secondary-nav.less";
@import "header/localization-menu.less";
@import "header/megamenu-popup.less";

.site-header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: @zindex-navbar-fixed;
  width: 100%;
  min-width: 310px;
  .transition(box-shadow 0.2s);
  &.compact {
    box-shadow: 0 1.5px 0.2px rgba(0, 0, 0, 0.5);
  }

  @media (max-width: @screen-xs-max) {
    &.mega-menu-open {
      position: relative;
    }
  }

  .container {
    height: 100%;
    margin: 0 auto;
    .clearfix;
    padding-right: 0px;
    padding-left: 0px;
    padding-top: 0px;
  }

  .site-header-top {
    background-color: @gray-lightest;
    line-height: @line-height-base;
    color: @gray-dark;
    a {
      color: @custom-black;
      &:hover {
        text-decoration: none;
      }
    }
    .transition(height 200ms linear);
    > .container {
      @media (max-width: @screen-xs-max) {
        padding-left: 14px;
        padding-right: 14px;
      }
      &,
      .additional-menu {
        &,
        > ul {
          &,
          > li {
            &,
            > a {
              height: 100%;
            }
          }
        }
      }
    }
    .header-top-bar {
      display: flex;
      align-items: flex-end;
      height: 100%;
      .top-header-logo {
        display: flex;
        align-items: center;
        height: 100%;
        margin-right: auto;

        @media (max-width: @screen-xs) {
          margin-right: 0px;
          padding-left: 0px;
          padding-right: 0px;
        }

        @media (max-width: @screen-sm-max) {
          padding-left: 0px;
          padding-right: 0px;
        }
        .logo {
          float: left;
          width: 220px;
          height: 100%;
          min-height: 34px;
          margin-right: 20px;

          background: url(../../../../assets/images/nameisp_logo_red.svg)
            no-repeat center left;
          background-size: contain;
          text-indent: -9999px;
          @media (max-width: @screen-xs) {
            min-width: 74px;
            margin-right: 0px;
          }
          @media (max-width: @screen-sm-max) {
            width: 100%;
            margin-right: 0px;
          }
        }

        .no-svg .logo {
          background: url(/assets/images/nameisp_logo_red.png) no-repeat center
            left;
        }
      }
    }

    .customer-panel {
      display: flex;
      justify-content: flex-end;
      font-family: Roboto, sans-serif;
      align-items: center;
      margin-top: 1px;
      @media (max-width: @screen-xs) {
        padding-left: 0px;
        padding-right: 0px;
        i {
          font-size: 1.2em;
        }
      }

      @media (max-width: @screen-sm-max) {
        padding-left: 0px;
        padding-right: 0px;
      }

      .icann-info {
        min-width: 180px;
        position: relative;
        font-size: 10px;
        font-style: normal;
        margin-right: 52px;
        border: none;
        @media (max-width: @screen-md) {
          font-size: 8px;
          margin-right: 30px;
        }
        p {
          margin: 0;
          padding: 0;
          text-align: right;
        }
        @media (max-width: @screen-xs-max) {
          display: none;
        }
      }
      .customer-account {
        
        i {
          position: relative;
          top: 1px;
        }
      }
      .language-picker {
        width: 102px;
        text-align: right;
        transition: all 0.2s;
        @media (max-width: @screen-sm) {
          height: 53px;
          text-align: inherit;
        }
        &:hover {
          background-color: @gray-lighter;
        }
        > a {
          padding: 17px 12px 18px 10px;
          @media (max-width: @screen-sm) {
            padding: 19px 6px;
            font-size: 12px;
          }

          @media (max-width: @screen-sm-max) {
            padding: 17px 12px 18px 10px;
          }

          @media (max-width: @screen-xs) {
            padding: 19px 14px;
            font-size: 12px;
          }
        }
        li {
          a:hover {
            color: @gray;
          }
        }
        @media (max-width: @screen-sm-max) {
          min-width: auto;
          text-align: none;
          padding-right: 0;
          width: auto;
          height: 53px;
        }
        > a {
          text-transform: uppercase;
          cursor: pointer;

          &:hover {
            background-color: @gray-lighter;
            color:#333333;   
          }
        }
      }
      .icann-info:after {
        content: "";
        background-image: url("../../../../assets/images/icann-accredited-registrar.png");
        background-size: contain;
        background-repeat: no-repeat;
        position: absolute;
        top: -1px;
        right: -40px;
        left: auto;
        width: 34px;
        height: 34px;
        @media (max-width: @screen-md) {
          width: 28px;
          height: 28px;
          top: -2px;
          right: -30px;
        }
      }
      i {
        margin-right: 5px;
        @media (max-width: @screen-xs) {
          margin-right: 10px;
          margin-left: 10px;
        }
      }
      div {
        border-right: 1px solid @gray-light;
        border-top: 1px solid @gray-light;
        line-height: 52px;
        // &:nth-child(2) {
        //   @media (max-width: @screen-sm-max) {
        //     padding: 0;
        //   }
        // }
        &::nth-child(4) {
          border-right: 1px solid @gray-light;
        }
      }
      span {
        @media (max-width: @screen-sm-max) {
          font-size: 10px;
        }
        @media (max-width: @screen-xs-min) {
          display: none;
        }
      }
    }

    .structural-navigation {
      float: left;
      position: relative;
      top: 50%;
      left: 0;
      margin-top: -16px;
      font-size: @font-size-small;
      a {
        color: lighten(@gray, 10%);
        &:hover,
        &:focus {
          color: @link-color;
          text-decoration: none;
        }
      }
      .home-link {
        margin-right: 10px;
        font-size: 18px;
      }
      .keyword-links {
        display: inline;
      }
      .section-title {
        display: inline;
        font-size: @font-size-large;
        color: @gray;
      }
    }

    .additional-menu {
      float: right;
      > ul {
        padding: 0;
        margin: 0;
        list-style: none;
        > li {
          float: left;
          display: table;
          position: relative;
          > a {
            font-size: @font-size-small;
            .heading-font;
            display: table-cell;
            vertical-align: middle;
            color: @brand-warning;
            text-decoration: none;
            padding: 2px 15px;
            .transition(background-color 0.2s);
            &:hover,
            &:focus {
              background-color: @gray-lighter;
            }
            @media (max-width: @screen-sm-max) {
              i {
                .transition(font-size 0.2s);
                .not-compact& {
                  font-size: 18px;
                }
              }
            }
          }
        }
      }
    }
  }

  .site-header-primary {
    // background-color: @brand-primary-medium;
    background-color: @brand-red;
    color: #fff;
    @media (max-width: @screen-sm-max) {
    }
    .btn-link {
      color: #fff;
    }
    > .container {
      position: relative;
      @media (max-width: @screen-xs-max) {
        padding-left: 0;
        padding-right: 0;
      }
      &,
      .site-header-row {
        height: 100%;
      }
    }
    .site-header-row {
      display: table;
      width: 100%;
      background-color: @brand-red;
      height: 100%;
      display: flex;
      align-items: center;
    }
    .nav-button {
      display: table;
      position: static;
      > .btn {
        display: table-cell;
        vertical-align: middle;
        height: @primary-header-height;
        width: @primary-header-height;
        padding-top: 0;
        padding-bottom: 0;
        border-width: 0;
        z-index: (@zindex-navbar-fixed + 1);
        .transition(all 0.2s);
        &:hover,
        &:focus {
          background-color: fade(#fff, 30%);
          text-decoration: none;
        }
        &:before {
          display: none;
        }
        z-index: (@zindex-navbar-fixed + 3);
        &.active {
          background-color: @gray-lightest;
          .nav-icon {
            color: @brand-primary-dark;
          }
          @media (min-width: @screen-sm) {
            &,
            &:after {
              border-left: 1px solid @gray;
              border-right: 1px solid @gray;
            }
          }
        }
      }
      .nav-icon {
        // .animate-on-scroll(font-size, 32px, 21px, color);
        font-size: 30px;
        @media (max-width: @screen-xs) {
          font-size: 21px;
        }
      }
    }

    .shopping-cart {
      color: #fff;
      background-color: @brand-red;
      transition: all 0.2s;
      position: relative;
      height: @primary-header-height;
      width: @primary-header-height;
      .icon-btn-icon {
        font-size: 26px;
        margin-bottom: -3px;
      }
      .icon-btn-label {
        margin-top: -3px;
        font-size: 14px;
      }
      &:hover {
        background-color: rgba(255, 255, 255, 0.3);
        text-decoration: none;
      }
      &.empty-cart {
        opacity: 0.8;
      }
      .badge {
        position: absolute;
        top: 10px;
        right: 14px;
        background-color: @complement-color;
        font-family: "Roboto", "Helvetica Neue", sans-serif;
        font-style: normal;
        min-width: 19px;
        padding: 3px 1px 3px 2px;
      }
    }
  }

  .site-menu {
    height: 100%;
    text-align: center;
    position: static;
  }

  .site-search {
    height: 100%;
    width: 100%;
    .input-group {
      width: 100%;
    }
    .form-control:focus {
      // border-color: @brand-primary-dark;
      border-color: #fff;
      box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.5);
    }
    padding: 7px;
    .form-control {
      border: none;
      .input-sm;
      padding-right: 60px;
    }
    .btn {
      .btn-sm;
    }
    .form-control,
    .btn {
      .translate3d(0,0,0);
      .transition(~"height .2s, padding .2s, font-size .2s, line-height .2s");
    }
    @media (min-width: @screen-sm) {
      .not-compact& {
        padding: 7px (@grid-gutter-width / 2);
        .form-control {
          .input-md;
        }
        .btn {
          .btn-md;
        }
      }
    }
    @media (min-width: @screen-md) {
      .not-compact& {
        .form-control {
          .input-lg;
        }
        .btn {
          .btn-lg;
          padding-left: (@padding-large-vertical * 0.75);
          padding-right: (@padding-large-vertical * 0.75);
        }
      }
    }
  }

  /*
  .site-header-icons {
    height: 100%;
    vertical-align: middle;
    img {
      padding-left: (@grid-gutter-width / 2);
      padding-right: (@grid-gutter-width / 2);
      width: auto;
      max-width: none;
      .not-compact& {
        max-height: ( @primary-header-height-xs - 6 );
      }
      @media (min-width: @screen-sm) {
        .compact& {
          min-width: ( @primary-header-height-sm - 6 );
          width: 100%;
          height: auto;
        }
      }
    }
  }
  */

  .user-menu {
    > ul {
      height: 100%;
      padding: 0;
      margin: 0;
      display: table;
      > li {
        display: table-cell;
        list-style: none;
        .icon-btn {
          height: 100%;
          color: #fff;
          padding-top: 0;
          padding-bottom: 0;
          border-width: 0;
          text-decoration: none;
          z-index: 1;
          .compact& {
            padding: 0;
          }
          .translate3d(0,0,0);
          .transition(~"background-color .2s, width .2s");
          &:hover,
          &:focus {
            background-color: fade(#fff, 30%);
            outline: none;
          }
          &.signout {
            @media (min-width: @screen-sm) {
              display: none;
            }
          }
          &.portfolio {
            &.active {
              background-color: @brand-primary-dark;
            }
            @media (max-width: @screen-xs-max) {
              display: none;
            }
          }
          .icon-btn-icon {
            font-size: 21px;
          }
          .badge {
            font-size: 8px;
            .transition(font-size 0.2s);
          }
          width: @primary-header-height-xs;

          @media (min-width: @screen-sm) {
            .not-compact& {
              width: @primary-header-height-sm;
              .icon-btn-icon {
                font-size: 32px;
              }
              .badge {
                font-size: 10px;
              }
            }
          }
          @media (min-width: @screen-md) {
            .not-compact& {
              width: @primary-header-height;
            }
          }
          @media (max-width: @screen-xs-max) {
            .icon-btn-icon {
              font-size: 21px !important;
            }
          }
        }
      }
    }
  }

  .site-header-secondary {
    display: none;
    position: relative;
    width: 100%;
    background: @brand-primary-dark;
    color: #fff;
    height: 60px;
    @media (max-width: @screen-xs-max) {
      height: 45px !important;
    }
    a {
      font-weight: bold;
      color: lighten(@brand-primary, 56%);
      &:focus,
      &:hover {
        color: #fff;
      }
    }
    p {
      margin-bottom: 0;
      line-height: 1.3;
      font-size: (@font-size-small - 1);
      -webkit-font-smoothing: antialiased;
    }
    .popover {
      a {
        font-weight: normal;
        color: @link-color;
        &:focus,
        &:hover {
          color: @link-hover-color;
        }
      }
      p {
        margin-bottom: (@line-height-computed / 2);
        line-height: @line-height-small;
        font-size: @font-size-small;
        -webkit-font-smoothing: auto;
      }
    }
    @media (max-width: @screen-xs-max) {
      .container {
        padding-left: (@grid-padding / 2);
      }
    }
    .site-header-secondary-inner {
      .animate-on-scroll(padding-top, 17px, 12px);
    }

    .site-header-authentication {
      position: relative;
      .animate-on-scroll(margin-top, 7px, 5px);
      @media (min-width: @screen-sm) {
        float: right;
      }
      .site-header-signin {
        display: table;
        width: 350px;
        margin-bottom: 5px;
        .signin-label {
          display: table-cell;
          color: inherit;
          padding-right: 5px;
          font-size: @font-size-small;
          width: 12%;
          white-space: nowrap;
          -webkit-font-smoothing: antialiased;
        }
        .signin-input {
          display: table-cell;
          width: 43%;
          padding-right: 7px;
          vertical-align: middle;
          input {
            width: 100%;
            height: 25px;
            padding: 3px 10px;
            background-color: @brand-primary-darker;
            border-color: @brand-primary-darker;
            color: #fff;
            .placeholder(@brand-primary-light);
            &:focus {
              background-color: @brand-primary;
            }
          }
        }
        .signin-submit {
          display: table-cell;
          height: 25px;
          padding: 3px 10px;
        }
      }

      .site-header-signin-assistance {
        .animate-on-scroll(height, 14px, 0);
        @media (min-width: @screen-sm) {
          overflow: hidden;
          text-align: right;
          float: right;
        }
        .site-header-signin-link {
          float: right;
          a {
            display: block;
            padding: 10px;
          }
        }
      }

      .site-header-signup {
        display: inline-block;
        text-align: left;
        margin: 0;
        height: 100%;
        color: #fff;
        font-weight: 700;
        padding-right: 5px;
        -webkit-font-smoothing: antialiased;
        @media (max-width: @screen-xs-max) {
          padding-top: 5px;
          .signup-label {
            display: inline !important;
            font-weight: normal;
          }
          a {
            display: block;
          }
        }
      }
      .site-header-forgot {
        padding-left: 5px;
      }
    }
  }

  .site-header-authenticated {
    float: right;
    text-align: right;
    padding: 0 10px;
    .signed-in-label {
      overflow: hidden;
      .animate-on-scroll(height, 14px, 0);
    }
    @media (max-width: @screen-xs-max) {
      //padding: 10px;
      //margin-right: -10px;
      display: none;
    }
  }

  .site-accreditation {
    position: relative;
    float: left;
    color: @brand-primary-light;
    white-space: nowrap;
    padding-left: 40px;
    height: 100%;
    .accreditation-icon {
      position: absolute;
      left: 0;
      top: 50%;
      .translate(0, -50%);
      height: 30px;
      img {
        height: 100%;
        width: auto;
      }
    }
    .accreditation-version {
      font-weight: bold;
      margin-bottom: 0;
    }
    .accreditation-description {
      overflow: hidden;
      .animate-on-scroll(height, 14px, 0);
      margin-bottom: 0;
    }
    @media (max-width: @screen-xs-max) {
      .site-header-secondary-inner {
        display: none;
      }
    }
  }

  .reguests-counter {
    position: relative;
    float: right;
    color: @brand-primary-light;
    padding: 0;
    margin: 0 15px;
    height: 100%;
    .min-4;
    .requests-description {
      margin-top: 10px;
      overflow: hidden;
      .animate-on-scroll(height, auto, 0);
    }
    .counter {
      position: absolute;
      left: 0;
      top: 50%;
      .translate(0, -50%);
      .animate-on-scroll(margin-top, 10px, 0);

      .requests-help {
        padding: 5px;
      }
    }
  }
}

.nationality-menu {
  display: none;
  background-color: white;
  position: absolute;
  top: 50px;
  right: 15px;
  z-index: 9999;
  min-width: 240px;
  border: 1px solid @gray-light;
  padding-right: 12px;
  padding-left: 12px;
  @media (max-width: @screen-sm) {
    min-width: auto;
    width: 200px;
  }

  @media (max-width: @screen-xs) {
    top: 52px;
    right: 0px;
  }

  ul {
    margin-right: 18px;
    li {
      text-align: left;
      padding: 4px 6px 4px 0;
      border-bottom: 1px solid @gray-light;
      
      &:last-child {
        border-bottom: none;
      }
    }
  }
  div {
    float: left;
    border: none !important;
    line-height: 32px !important;
  }
  p {
    padding: 16px 0 4px 0;
    margin: 0;
    color: @gray-dark;
    text-align: left;
  }
  i {
    opacity: 0;
    font-size: 15px;
    color: @brand-success;
    &.fa-sort-desc {
      position: relative;
      top: -25px;
      left: -24px;
      color: @gray;
      font-size: 20px;
      opacity: 1;
      @media (max-width: @screen-sm) {
        left: -23px;
      }
      @media (max-width: @screen-xs) {
        left: -12px;
        top: -27px;
      }
    }
  }
  .checked {
    opacity: 1;
    position: relative;
    top: 0px;
    left: 0px;
  }
  .show {
    display: block;
  }
  a {
    cursor: pointer;
  }
}

.header-search-field {
  display: block;
  width: 100%;
  margin: 0 18px;
  .form-control {
    height: 44px;
    font-size: 16px;
    box-shadow: none;
    border: none;
    &:focus {
      border-color: white;
      box-shadow: 0px 1px 10px lightgrey;
    }
  }
  .input-group {
    width: 100%;
    input {
      @media (max-width: @screen-sm) {
        font-size: 16px;
      }
    }
  }
  .button {
    position: absolute;
    right: auto;
    top: 1px;
    z-index: 100;
    border: none;
    height: 42px;
    &:hover,
    &:focus,
    &:active {
      // background-color: @gray-lightest;
    }
    &.search {
      right: 24px;
      padding: 0 12px 0 12px;
      @media screen and (max-width: @screen-sm) {
        right: 0;
      }
      i {
        color: @gray;
      }
    }
    &.alt-search {
      @media screen and (max-width: @screen-sm) {
        display: none;
      }
      right: 2px;
      padding: 0 5px 0 5px;
      i {
        color: @brand-red;
        font-weight: bold;
        font-size: 20px;
        position: relative;
        top: 4px;
      }
    }
  }
  .spec-search-list {
    position: absolute;
    right: 0;
    top: 43px;
    background: #fff;

    position: absolute;
    z-index: 1000;
    background-color: #fff;
    border: 1px solid #ebebed;

    li {
      padding: 8px 12px;
      a {
        color: @gray-darker;
        cursor: pointer;
        &:hover, &:focus {
          color: @link-hover-color;
        }
      }
      i {
        opacity: 0;
        color: @gray;
        font-size: 20px;
        position: relative;
        left: -13px;
        top: 2px;
      }
      i.active {
        opacity: 1;
      }
    }
  }
}

.initial-top-menu {
  display: block !important;
  animation-duration: 0.4s;
  height: 85px;
}

.hide-top-menu {
  animation-duration: 0.4s;
  animation-name: hide;
  height: 0px;
}

@keyframes hide {
  from {
    height: 52px;
  }
  to {
    height: 0px;
  }
}

.customer-panel-list {
  ul  {
    display: flex;
    margin-bottom: 0;
    li {
      height: 52px;
      @media (max-width: @screen-md) {
          margin: 0 0 0 0;
        }
      a {
        border-left: 1px solid @gray-light;
        padding: 17px 12px 18px 15px;
        transition: all 0.2s;
        @media (max-width: @screen-sm) {
          padding: 22px 8px;
          font-size: 10px;
        }
        @media (max-width: @screen-xs) {
          padding: 19px 15px;
          font-size: 12px;
        }
        &:hover {
          background-color: @gray-lighter;
          color:#333333; 
        }
      }

      span {
        @media (max-width: @screen-xs) {
          text-indent: 100%;
          white-space: nowrap;
          overflow: hidden;
        }
      }
    }
  }
}

.site-header-row {
  .site-menu {
    .nav-button {
      width: auto;
    }
    button {
      padding: 24px;
      @media (max-width: @screen-md) {
        padding: 12px;
      }
    }
  }
}
@media (max-width: @screen-sm) {
  .nationality-menu {
    .language-list{
      padding-left: 0 !important;
    }
  }
}
@media (max-width: @screen-xs) {
  .nationality-menu {
    line-height: 0px !important;

    ul {
      margin-right: 0px !important;
    }

    .language-list {
      width: 100%;
    }
    .currency-list {
      width: 100%;
    }
  }
}
