tags-input {
  display: block;
}
tags-input *, tags-input *:before, tags-input *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
tags-input .host {
  position: relative;
  margin-top: 5px;
  margin-bottom: 5px;
  height: 100%;
}
tags-input .host:active {
  outline: none;
}

tags-input .tags {
  -moz-appearance: textfield;
  -webkit-appearance: textfield;
  padding: 1px;
  overflow: hidden;
  word-wrap: break-word;
  cursor: text;
  background-color: white;
  border: 1px solid darkgray;
  box-shadow: 1px 1px 1px 0 lightgray inset;
  height: 100%;
}
tags-input .tags.focused {
  outline: none;
  -webkit-box-shadow: 0 0 3px 1px rgba(5, 139, 242, 0.6);
  -moz-box-shadow: 0 0 3px 1px rgba(5, 139, 242, 0.6);
  box-shadow: 0 0 3px 1px rgba(5, 139, 242, 0.6);
}
tags-input .tags .tag-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
tags-input .tags .tag-item {
  margin: 2px;
  padding: 0 5px;
  display: inline-block;
  float: left;
  font: 14px "Helvetica Neue", Helvetica, Arial, sans-serif;
  height: 26px;
  line-height: 25px;
  border: 1px solid #acacac;
  border-radius: 3px;
  background: -webkit-linear-gradient(top, #f0f9ff 0%, #cbebff 47%, #a1dbff 100%);
  background: linear-gradient(to bottom, #f0f9ff 0%, #cbebff 47%, #a1dbff 100%);
}
tags-input .tags .tag-item.selected {
  background: -webkit-linear-gradient(top, #febbbb 0%, #fe9090 45%, #ff5c5c 100%);
  background: linear-gradient(to bottom, #febbbb 0%, #fe9090 45%, #ff5c5c 100%);
}
tags-input .tags .tag-item .remove-button {
  margin: 0 0 0 5px;
  padding: 0;
  border: none;
  background: none;
  cursor: pointer;
  vertical-align: middle;
  font: bold 16px Arial, sans-serif;
  color: #585858;
}
tags-input .tags .tag-item .remove-button:active {
  color: red;
}
tags-input .tags .input {
  border: 0;
  outline: none;
  margin: 2px;
  padding: 0;
  padding-left: 5px;
  float: left;
  height: 26px;
  font: 14px "Helvetica Neue", Helvetica, Arial, sans-serif;
}
tags-input .tags .input.invalid-tag {
  color: red;
}
tags-input .tags .input::-ms-clear {
  display: none;
}
tags-input.ng-invalid .tags {
  -webkit-box-shadow: 0 0 3px 1px rgba(255, 0, 0, 0.6);
  -moz-box-shadow: 0 0 3px 1px rgba(255, 0, 0, 0.6);
  box-shadow: 0 0 3px 1px rgba(255, 0, 0, 0.6);
}

tags-input .autocomplete {
  margin-top: 5px;
  position: absolute;
  padding: 5px 0;
  z-index: 999;
  width: 100%;
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}
tags-input .autocomplete .suggestion-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
tags-input .autocomplete .suggestion-item {
  padding: 5px 10px;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font: 16px "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: black;
  background-color: white;
}
tags-input .autocomplete .suggestion-item.selected {
  color: white;
  background-color: #0097cf;
}
tags-input .autocomplete .suggestion-item.selected em {
  color: white;
  background-color: #0097cf;
}
tags-input .autocomplete .suggestion-item em {
  font: normal bold 16px "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: black;
  background-color: white;
}
