@import "bootstrap/less/mixins/vendor-prefixes.less";

.transform(@args) {
  -webkit-transform: @args;
  -ms-transform: @args;
  transform: @args;
}

.column-count(@args) {
  -moz-column-count: @args;
  -webkit-column-count: @args;
  column-count: @args;
}

.transition(@args) {
  -webkit-transition: @args;
  -ms-transition: @args;
  transition: @args;
}