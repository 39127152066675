.block-variant(@color) {
    border-color: @color;
    h1, h2, h3 {  color: @color;}
  	p {           color: @color;}
}

.block {
  color: rgba(0, 162, 255, 1);
  border: 2px dashed @gray;
	display: block;
	padding: 13px 15px;
	text-decoration: none;
	
	.panel-sidebar & {
  	margin: 20px;
	}
	
	h1, h2, h3 {
		font-weight: bold;
		font-size: @font-size-base;
    color: @gray;
	}
	
	p {
		font-size: @font-size-small;
		margin: 5px 0px 0px 0px;
		color: @gray;
	}
	a&:hover, a&:focus {
		border-style: solid;
    text-decoration: none;
	}
}

.block-default {.block-variant(@gray);}
.block-info {.block-variant(@brand-info);}
.block-success {.block-variant(@brand-success);}
.block-danger {.block-variant(@brand-danger);}
.block-warning {.block-variant(@brand-warning);}
.block-inverse {.block-variant(#fff);}
