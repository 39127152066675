@font-face {
  font-family: 'Asap';
  font-style: italic;
  font-weight: 700;
  src: local('Asap Bold Italic'), local('Asap-BoldItalic'), url(https://fonts.gstatic.com/s/asap/v11/KFOlCniXp96ayz4mWUlfBBc9.ttf) format('truetype');
}
@font-face {
  font-family: 'Asap';
  font-style: normal;
  font-weight: 400;
  src: local('Asap Regular'), local('Asap-Regular'), url(https://fonts.gstatic.com/s/asap/v11/KFOoCniXp96ayzse5Q.ttf) format('truetype');
}
@font-face {
  font-family: 'Asap';
  font-style: normal;
  font-weight: 700;
  src: local('Asap Bold'), local('Asap-Bold'), url(https://fonts.gstatic.com/s/asap/v11/KFOnCniXp96aw4A79UtvAw.ttf) format('truetype');
}
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: local('Roboto'), local('Roboto-Regular'), url(https://fonts.gstatic.com/s/roboto/v20/KFOmCnqEu92Fr1Mu4mxP.ttf) format('truetype');
}
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: local('Roboto Medium'), local('Roboto-Medium'), url(https://fonts.gstatic.com/s/roboto/v20/KFOlCnqEu92Fr1MmEU9fBBc9.ttf) format('truetype');
}
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: local('Roboto Bold'), local('Roboto-Bold'), url(https://fonts.gstatic.com/s/roboto/v20/KFOlCnqEu92Fr1MmWUlfBBc9.ttf) format('truetype');
}
