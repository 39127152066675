@import "bootstrap/less/modals.less";


.modal-body {
  overflow: auto;
}
.modal-header {
  padding: 10px;
  .modal-title {
    padding-top: 0;
  }
  .close {
    margin-top: 5px;
  }
}
.modal-footer {
  padding: 10px;
}
.modal-header-sub {
  background: @gray-lighter;
  .modal-subheading {
    margin-bottom: 0;
  }
}

.modal-bg-dark {
  &.in {
    opacity: .8;
  }
}

//Modal-section
.modal-section {
  position: relative;
  .modal-body;
  border-top: 1px solid @modal-header-border-color;
  .modal-header + & {
    border-top: none;
  }
}

.modal-section-checkable {
  padding-left: 50px;
}
.modal-section-checkbox {
  position: absolute;
  left: 20px;
  top: 50%;
  .translate(0, -50%);
}

.modal-tab-navigation {
  margin-top: -1px;
  border-top: 1px solid #fff;
}

.modal-body-fieldset {
  background-color: @gray-lightest;
}

//Full width modal
.modal-dialog.modal-fw {
  width: @modal-fw;
  .modal-content {
    border-radius: 0;
  }
}

@media screen and (min-height: 700px) {
  .modal-dialog {
    margin-top: 150px;
  }
}