
.checkout-side {
	border-bottom: 1px solid @brand-grey-custom;
	border-right: 1px solid @brand-grey-custom;
}

body.page-checkout-index #content {
	.checkout-step {
		&.customer-details {
			.login {
				padding: 10px 20px;
				margin: 0 0 20px 0;
				background: #efefef;
				border: 1px solid #e0dfdf;
				
				h2 {
					margin: 0;
					padding: 0;
					
					a {
						margin-left: 10px;
					}
				}
			}
			
			form {
				section {
					padding: 25px 0 20px 0;
					
					.form-item {
						margin-bottom: 20px;
						
						input {
							width: 417px;
						}
						
						select {
							width: 424px;
						}
					}
				}
			}
		}
		
		&.payment {
		}
		
		.button.continue {
			float: right;
		}
	}
}