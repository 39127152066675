@import "bootstrap/less/forms.less";



form {
  display: inline;
}
.form {
  margin-bottom: @dub-unit;
}
legend {
  .type-size(@font-size-h4);
  font-weight: normal;
  border: none;
  margin-bottom: @unit;
  color: @gray-darker;
}
label {
  color: @gray-dark;
}
.form-control {
  word-break: normal;
  min-width: 50px;
  color: @gray-darker;
  .placeholder(@gray);
  input&, textarea&, select& {
    @media (max-width: @screen-xs-max) {
      .touchevents & {
        .placeholder(@gray, @font-size-small, 2);
        font-size: 16px !important;
      }
    }
  }
  &::-ms-clear {
    display: none;
  }
}

.form-control[disabled], .form-control[readonly], fieldset[disabled] .form-control {
  cursor: default;
  background: @gray-lightest;
}

td .form-group {
  margin-bottom: 0;
}

select.form-control {
  color: @gray-darker;
}

select.form-control {
  .pagination & {
    max-width: 170px;
    display: inline-block;
    float: none;
  }
}
div.form-control.select2-container {
  .pagination & {
    max-width: 170px;
    display: inline-block;
    float: none;
    top: 0;
  }
}

.input-md {
  .input-size(@input-height-base; @padding-base-vertical; @padding-base-horizontal; @font-size-base; @line-height-base; @border-radius-base);
}

.input[type=checkbox], .input[type=radio] {
  .form-control-static & {
    vertical-align: top;
  }
}

input[type="checkbox"], input[type="radio"] {
  &.enhanced {
    //display:none;
    position: absolute;
    left: -9999em;
    & + i:before {
      .fa;
    }
    li & + i {
      margin-left: -5px;
      margin-right: 5px;
    }
  }
}

input[type="radio"].enhanced + i:before {
  .far;
  content: @fa-var-circle; /* circle-blank */
}

input[type="radio"].enhanced:checked + i:before {
  .far;
  content: @fa-var-dot-circle; /* circle */
}

input[type="checkbox"].enhanced + i:before {
  .far;
  content: @fa-var-square; /* check-empty */
}

input[type="checkbox"].enhanced:checked + i:before {
  content: @fa-var-check-square; /* check */
}

input[type="checkbox"].enhanced.semi-checked + i:before {
  content: @fa-var-minus-square; /* semi-checked */
}

input[type="radio"].enhanced, input[type="checkbox"].enhanced {
  + i {
    vertical-align: middle;
  }
  + i:before {
    color: @text-color;
  }
  &:focus + i:before,
  + i:hover:before, &:checked + i:before, &.semi-checked + i:before {
    color: #000;
  }
  &:checked:focus + i:before {
    //text-shadow: 0 0 1px #000;
  }
}


//Required Asterisk
.required-note,
.info-note {
  display: inline-block;
  padding: 5px;
  width: 25px;
  height: 23px;
  cursor: default;
  font-style: normal;
  text-align: center;
  dt &, .list-label & {
    position: absolute;
    right: -15px;
    top: 0;
    //z-index: 1;
  }
  @media screen and (max-width: @screen-xs-max) {
    dt &, .list-label & {
      position: static;
    }
  }
}

.required-note {
    color: @brand-danger;
    z-index: 9;
}
.info-note {
    color: @brand-info;
    z-index: 10;
}


//Form validation
.validation {
  &:not(.ng-hide) { display: block !important; }
  font-size: 12px;
  padding: 3px 7px 6px;
  margin-bottom: @half-unit;
  .list-row & {
    position: relative;
    top: 5px;
  }
  &:before {
    margin-right: 5px;
  }
}
.validation.error { color: @brand-danger; .glyph; .glyph-error;}
.validation.warning { color: @brand-warning; .glyph; .glyph-warning;}
.validation.success { color: @brand-success; .glyph; .glyph-success;}
.validation.info { color: @brand-info; .glyph; .glyph-info;}

.ng-dirty.ng-invalid {
  input&, textarea& {
    border-color: @brand-danger !important;
    outline: 0;
    box-shadow: 0 0 8px fade(@brand-danger,60%) !important;
  }
}
 
 
 //Phone input control
 .phone-control {
   display: table;
   margin: -5px -3px 0;
   &-prefix {
     display: table-cell;
     vertical-align: top;
     min-width: 81px;
     width: 15%;
     padding: 0 3px;
     select {
       white-space: nowrap;
       margin-top: -1px;
       padding-left: 5px;
       padding-right: 0px;
     }
   }
   &-number {
     display: table-cell;
     vertical-align: top;
     width: 85%;
     min-width: 86px;
     padding: 0 3px;
     input {
       padding-left: 5px;
       padding-right: 5px;
     }
   }
 }