body.page-index-index #content {
	.grid {
		.box {
			&.whois {
				background: #00a1fe;
				color: #fff;
				
				header {
					border-bottom: 1px solid #5ec4fe;
				}
				
				.widget {
					margin: 0;
					box-shadow: none;
					outline: 0;
					background: #5ec4fe;
					border: none;
					
					.form-item {
						margin-bottom: 0;
					}
				}
			}
		}
	}
}