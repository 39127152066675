.popup.login {
	width: 300px;
	
	h2 {
		padding: 0;
	}
	
	form {
		.clearfix;
		border-bottom: 1px solid #ddd;
		padding-bottom: 15px;
		
		.form-item {
			margin-bottom: 15px;
		}
		
		.button {
			float: right;
		}
		
		a {
			float: left;
			line-height: 32px;
		}
	}
	
	.register {
		padding-top: 15px;
		border-top: 1px solid #fff;
	}
}