.site-footer {
    a:hover,
    a:focus {
        text-decoration: none;
    }
    .footer-navigation {
        background: @gray-lighter;
        border-top: 1px solid #ddd;
        padding-top: 30px;
    }
    .public-navigation-body {
        .make-row();
    }
    .public-primary-navigation {
        >* {
            @media (min-width: @screen-sm) {
                width: 33%;
            }
        }
    }
}

.footer-contact {
    text-align: center;
    font-size: @font-size-small;
    h2 {
        padding-top: 10px;
    }
    @media (max-width: @screen-sm-max) {
        clear: both;
        float: none;
        margin: 0 auto 30px;
        padding-top: 10px;
        border-top: 1px solid @gray-light;
    }
}

.footer-icons {
    padding: 0;
    list-style: none;
    display: table;
    margin: 40px auto 0;
    table-layout: fixed;
    li {
        display: table-cell;
    }
}

.footer-siteinfo {
    background: @gray-lighter;
    .clearfix;
    line-height: 14px;
    color: @gray-dark;
    .footer-siteinfo-group {
        padding: 30px;
        @media (max-width: @screen-xs-max) {
            padding: 0;
        }
    }
    .logo {
        max-width: 160px;
        margin-right: 24px;
    }
    .icann-logo {
        width: 50px;
    }
    .header {
        height: 50px;
        margin-bottom: 16px;
        display: flex;
        align-items: center;
        h4 {
          margin: 0;
        }
    }
    
    .about {
        div {
            margin-top: 12px;
        }
        @media (max-width: @screen-sm) {
            padding-top: 20px;
        }
    }
    .about,
    .contact-us,
    .link-list {
        font-size: 1em;
        @media (max-width: @screen-md) {
            text-align: center;
            h4 {
                width: 100%;
            }
        }
        @media (max-width: @screen-xs) {
            text-align: left;
            font-size: 14px;
        }
        span {
            font-size: 18px;
            padding-right: 6px;
        }
        li {
            margin-bottom: 14px;
        }
    }
    .call-us {
        margin-bottom: 24px;
    }
    .footer-phone {
        color: @complement-color-dark;
        font-size: 1.5em;
        margin-bottom: 2px;
        font-weight: 700;
        span {
            font-size: 1em;
        }
        i {
            font-size: 26px;
        }
    }
    .footer-content-top {
        display: flex;
    }
}

.footer-payment-methods.logo-group {
    text-align: center;
    margin-bottom: 0;
    img {
        width: auto;
        max-height: 44px;
        max-width: 66px;
        margin: 0px 8px 34px 4px;
    }
    @media (max-width: @screen-sm-max) {
        margin: 20px;
    }
}

.sub-footer {
    padding: 20px 0;
    background: @brand-grey;
    border-top: @gray-light 1px solid;
    color: @gray;
    font-size: @font-size-base;
    .container {
        padding: 0 30px 0 30px;
        @media (max-width: @screen-xs) {
            padding: 0 15px 0 15px;
        }
    }
    .copyright {
        float: left;
        @media (max-width: @screen-sm-max) {
            display: block;
            margin-top: 10px;
            float: none;
            text-align: center;
        }
    }
    .sub-footer-links {
        float: right; //Code Review : Ta bort border-separator mellan twitter och facebook
        @media (max-width: @screen-sm) {
            float: none;
            text-align: center;
            .social-links {
                white-space: nowrap;
            }
        }
        @media (max-width: @screen-xs) {
            // text-align: left;
        }
        .links,
        .social-links {
            display: inline;
            padding: 0;
            li {
                display: inline;
                margin-right: 10px;
                padding-right: 10px;
                @media (max-width: @screen-xs) {
                    border: none;
                }
                &:last-child {
                    border-right: none;
                    padding-right: 0;
                }
                @media (max-width: @screen-xs) {}
                a {
                    color: @gray;
                    text-decoration: none;
                    &:hover {
                        color: @gray-light;
                    }
                    i {
                        vertical-align: unset;
                        font-size: 16px;
                        margin-right: 2px;
                    }
                    @media (max-width: @screen-sm-max) {
                        display: inline-block;
                        padding: 10px;
                    }
                }
            }
        }
        .links {
            padding-right: 20px;
        }
        .social-links {
            li {
                border: none;
            }
        }
    }
}