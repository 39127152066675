.button {
	display: block;
	box-sizing: border-box;
	position: relative;
	height: 32px;
	margin: 0;
	padding: 5px 20px;
	border: 1px solid @gray-lightest;
	text-decoration: none;
	cursor: pointer;
	.transition(all 80ms linear);
	background: @brand-primary-dark;
	text-align: center;
	
	&:before {
		content: '';
		display: inline-block;
		height: 100%;
		vertical-align: middle;
		margin-right: -0.25em;
	}
	
	.button-inner {
		display: inline-block;
		position: relative;
		text-align: center;
		vertical-align: middle;
		font-size: @font-size-large;
		font-family: Roboto, Arial, sans-serif;
		white-space: nowrap;
		color: #fff;
		.transition(all 200ms linear);

		.button-label {
			text-align: center;
			position: relative;
		}
		
		.button-icon {

		}
	}
	
	&.icon-button {
		.button-inner {
			.button-label {
				padding-left: 8px;
			}
		}
	}
	
	&.label-below-icon {
		.button-icon {
			display: block;
		}
		
		.button-label {
			display: block;
		}
	}
	
	&:hover, &:active {
		text-decoration: none;
		outline: none;
	}
	
	&:focus {
		outline: none;
	}
	
	&.white {
		background-color: @btn-default-bg;
		border: 1px solid #ddd;
		
		&:hover {
			border-color: @btn-default-bg;
		}
		
		&:active {
			border: 1px solid #ddd;
		}
		
		.button-icon {
			color: #23acfd;
		}
		
		.button-label {
			color: @btn-default-color;
		}
	}
	
	&.grey {
		border: none;
		background-color: #fafafa;
		
		&:hover {
			border: none;
			box-shadow: none;
		
			.button-icon {
				color: #23acfd;
			}
		}
		
		.button-icon {
			padding-right: 10px;
			color: #444;
		}
		
		.button-label {
			color: @btn-default-color;
		}
	}
	
	&.white-green {
		height: 32px;
		padding: 0 20px;
		border: 1px solid #fff;
		background-color: #fff;
		box-shadow: 0 1px 2px rgba(0, 0, 0, 0.18);
		
		&:hover,
		&:focus {
			border: 1px solid #00a42f;
			background-color: #fff;
		}
		
		.button-label {
			color: #444;
		}
	}
	
	&.warning {
		background: #fff;
		border-color: #fff;
		
		.button-inner {
			color: #444;
		}
		
		&:hover {
			border-color: #f55;
		}
	}
	
	&.blank {
		background-color: transparent;
		box-shadow: none;
		
		&:active {
		}
		
		&:hover {
			background-color: transparent;
			border-color: @gray-lightest;
		}
		
		.button-inner {
			color: @gray-dark
		}
	}
	
	&.blue {
		float: right;
		width: auto;
		height: 42px;
		padding: 0 20px;;
		background-color: #0077b2;
		border: 1px solid #fff;
		
		&:active {
		}
		
		&:hover,
		&:focus {
			background-color: #009ef9;
		}

		&:active {
		}
		
		.button-label {
			color: #fff;
			font-size: 24px;
			width: 100%;
		}
	}
	
	&.action {
		height: 42px;
		background-color: #ff9616;
				
		&:hover {
			border-color: #ff9616;
		}
		
		&:active {
			border: 1px solid @gray-lightest;
		}
		
		.button-inner {
			color: #fff;
		}
	}
	
	&.strict {
		height: 42px;
		padding: 0;
		background: #f1f1f1;
		border: none;
		
		&:hover {
			background: #F7F7F7;
		}
		
		&:active {

		}
		
		.button-label {
			color: #444;
		}
	}
	
	&.toggle {
		border-bottom: none;
		
		.toggle-overlay {
			visibility: hidden;
			position: absolute;
			z-index: 9999;
			left: 0;
			bottom: -2px;
			width: 100%;
			height: 3px;
			background: #fafafa;
		}
		
		&.toggled .toggle-overlay {
			visibility: visible;
		}
	}
}

.toggle-button-wrap {
	position: relative;
}

//From ControlPanel framework
.btn-bar {
  .btn-block;
  text-align: left;
  padding-left: @padding-base-horizontal;
  padding-right: @padding-base-horizontal;
  .caret {
    float: right;
    margin-top: 7px;
  }
}

.btn-bar-group {
  width: 100%;
  button-select& {
    display: block;
    > .btn-group {
      width: 100%;
      > .btn {
        .btn-bar;
      }
    }
  }
}