.grid {
	margin-bottom: 60px;
	
	> .col {
		float: left;
		height: 100%;
		padding: 0 5px;
		vertical-align: top;
		
		&.first {
			padding: 0 5px 0 0;
		}
		
		&.last {
			padding: 0 0 0 5px;
		}
	}
	
	&.same-height {
		display: table;
		
		> .col {
			float: none;
			display: table-cell;
		}
	}
	
	&.grid-3 {
		> .col {
			width: 30%;
			
		}
	}
	
	.box {
		width: 100%;
		
		header {
			padding: 15px;
			border-top: 2px solid #23acfd;
			border-bottom: 1px solid #23acfd;
			
			h2 {
				margin: 0;
				padding: 0;
				font-size: 20px;
			}
		}
		
		.box-inner {
			padding: 15px;
		}
	}
}