#content {
  .container {
    @media (max-width: @screen-xs) {
      margin-top: 25px;
    }
  }
}

img {
  max-width: 100%;
}

main article {
  img, li {
    margin-bottom: ( @line-height-computed / 2 );
  }
  a[href*=".pdf"] {
    &:before {
      .far;
      content: @fa-var-file-pdf;
      margin-right: 4px;
      vertical-align: text-top;
    }
  }
  a[href*="mailto:"] {
    &:before {
      .far;
      content: @fa-var-envelope;
      margin-right: 4px;
      vertical-align: text-top;
    }
  }
}

a[class*='link-'] {
  &:before {
    .far;
    margin-right: 6px !important;
    vertical-align: text-top;
  }

}
.link-pdf:before {
  content: @fa-var-file-pdf !important;
}
.link-external:before {
  content: @fa-var-external-link !important;
}
.link-internal:before {
  content: @fa-var-link !important;
}
.link-email:before {
  content: @fa-var-envelope !important;
}
.link-file:before {
  content: @fa-var-file !important;
}
.link-locked:before {
  content: @fa-var-lock !important;
}

iframe {
  max-width: 100%;
  overflow: auto;
}

hr {
  border-color: @gray-light;
}

[class*=col-] {
  @media (max-width: @screen-xs-max) {
    &.pull-right {
      float: none !important;
      width: auto;
    }
  }
}

.align-center {
  margin: 0 auto;
  float: none;
}
.align-left {
  float: left;
  margin-right: @dub-unit;
}
.align-right {
  float: right;
  margin-left: @dub-unit;
}

@media (max-width: @screen-xs-max) {
  .align-left,
  .align-right {
    float: none;
    margin: 0 auto 15px;
  }
}

.align-top {
  &, & td, & td .btn, & td .btn-group {
    vertical-align: top !important;
  }
}
.align-middle {
  &, & td, & td .btn, & td .btn-group {
    vertical-align: middle !important;
  }
}
.align-bottom {
  &, & td, & td .btn, & td .btn-group {
    vertical-align: bottom !important;
  }
}
.auto-width {
  width: auto !important;
}
.word-break {
  word-break: break-all;
}
.inline {
  display: inline-block;
}
.clear {
  clear: both !important;
}

a.disabled {
  color: @gray !important;
  pointer-events: none;
  cursor: text;
  &:hover, &:focus {
    text-decoration: none;
    outline: none;
  }
  .site-header-primary &,
  .site-header-secondary & {
    color: @gray-light !important;
  }
}

.indent-1 {
  margin-left: @grid-gutter-width !important;
}
.indent-2 {
  margin-left: 2*@grid-gutter-width !important;
}
.indent-3 {
  margin-left: 3*@grid-gutter-width !important;
}

.gap-half {
  margin-top: @half-unit !important;
}
.gap-1 {
  margin-top: @unit !important;
}
.gap-2 {
  margin-top: @dub-unit !important;
}
.gap-3 {
  margin-top: @tri-unit !important;
}


.min-1 {
  min-width: 25px !important;
}
.min-2 {
  min-width: 50px !important;
}
.min-3 {
  min-width: 100px !important;
}
.min-4 {
  min-width: 190px !important;
}
.min-5 {
  min-width: 240px !important;
}
.min-6 {
  min-width: 320px !important;
}
.min-7 {
  min-width: 400px !important;
}

[class*=max-] {
  &.badge {
    .text-overflow();
  }
}

.max-1 {
  max-width: 25px !important;
  tr & {
      width: 25px !important;
    }
}
.max-2 {
  max-width: 50px !important;
    tr & {
      width: 50px !important;
    }
}
.max-3 {
  max-width: 125px !important;
    tr & {
      width: 125px !important;
    }
}
.max-4 {
  max-width: 175px !important;
    tr & {
      width: 175px !important;
    }
}
.max-5 {
  max-width: 275px !important;
    tr & {
      width: 275px !important;
    }
}
.max-6 {
  max-width: 400px !important;
    tr & {
      width: 400px !important;
    }
}


//Badge colors
.badge-danger {
  background-color: @brand-danger !important;
}
.badge-info {
  background-color: @brand-info !important;
}
.badge-success {
  background-color: @brand-success !important;
}
.badge-warning {
  background-color: @brand-warning !important;
}
.badge-primary {
  background-color: @brand-primary !important;
}

//Touchevents support

.visible-touch {
  .no-touch & {display: none;}
}
.hidden-touch {
  .touchevents & {display: none;}
} 
