body {
	background: @gray-darker;
	
	.container {
		@media (min-width: @screen-sm) {
  		width: @container-sm;
  		padding: 0;
		}
		@media (min-width: @screen-md) {
  		width: @container-md;
		}
		@media (min-width: @screen-lg) {
  		width: @container-lg;
		}
	}
	
	

}