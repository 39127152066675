.data-grid-small {
  margin-bottom: 40px;
  border: 1px solid #d4d4d4;
  border-top: 4px solid @brand-grey;
  .data-grid-inner {
    padding: 0px 15px 0 15px;
  }
  table {
    border-collapse: collapse;
    width: 100%;
    background-color: #fff;
    font-size: 12px;

    caption {
      padding: 10px 0;
      // background: #efefef;
      // border-bottom: 1px solid #ddd;
      font-size: 16px;
      color: #444;
      font-weight: bold;
      display: inline-block;
      h2 {
        padding-top: 5px;
        white-space: nowrap;
      }
    }

    thead th {
      padding: 10px 0 8px 15px;
      border-bottom: 1px solid #ddd;
      text-align: left;
      font-weight: bold;
    }

    tbody {
      tr {
        // background-color: #f9f9f9;

        td,
        th {
          padding: 6px 0px 7px 0px;
          // border-bottom: 1px solid #ddd;
          text-align: left;
          vertical-align: middle;
          font-size: 12px;

          input {
            vertical-align: sub;
          }
        }

        th {
          background: #fff;
          border-right: 1px solid #ddd;
          font-weight: bold;

          &:last-child {
            border-right: none;
          }
        }

        td:nth-child(even) {
          text-align: right;
        }

        &:last-child {
          td,
          th {
            border-bottom: none;
          }
        }
      }
    }
    .table-more-toggle {
      a {
        background-color: #fff;
        &:hover {
          background-color: @gray-lightest;
        }
        &:focus {
          background-color: #fff;
          &:hover {
            background-color: @gray-lightest;
          }
        }
      }
    }
  }


  &.clickable-rows {
    table tbody tr:hover {
      th,
      td {
        background-color: @state-info-border;
        cursor: pointer;
        a {
          text-decoration: underline;
          color: @link-hover-color;
        }
      }
    }
  }

  .tld-flag {
    float: right;
    position: absolute;
    top: 6px;
    right: 30px;
  }
  
}

.data-grid-large {
  margin-bottom: 40px;
  border: 1px solid #d4d4d4;
  border-top: 4px solid @brand-grey;

  table {
    border-collapse: collapse;
    width: 100%;
    background-color: #fff;
    font-size: 12px;

    caption {
      padding: 10px 0;
      font-size: 16px;
      color: #444;
      font-weight: bold;
      text-align: center;
      h2 {
        padding-top: 2px;
        margin: 0;
      }
    }

    td {
      width: 50%;
      border-top: 1px solid @brand-grey;
      border-bottom: 1px solid @brand-grey;
      border-right: none;
      border-left: none;
      padding:16px 0px 16px 0px;
      font-size: 1em;
      font-weight: 400;


      &:nth-child(even) {
        text-align: left;
        padding-left: 20px;
      }
      &:nth-child(odd) {
        text-align: right;
        padding-right: 20px;
      }
    }

    .table-more-toggle {
      a {
        background-color: #fff;
        &:hover {
          background-color: @gray-lightest;
        }
        &:focus {
          background-color: #fff;
          &:hover {
            background-color: @gray-lightest;
          }
        }
      }
    }
  }

  &.clickable-rows {
    table tbody tr:hover {
      th,
      td {
        background-color: @state-info-border;
        cursor: pointer;
        a {
          text-decoration: underline;
          color: @link-hover-color;
        }
      }
    }
  }
}

.data-text-box {
  margin-bottom: 40px;
  h2 {
    padding-bottom: 12px;
  }
  li {
    border-right: none;
    border-left: none;
    padding:16px 0 16px 6px;
    font-size: 1em;
    font-weight: 400;
    i {
      font-size: 9px;
      position: relative;
      top: 6px;
      float: left;
      color: #96959a;
    }
    span {
      display: block;
      padding-left: 30px;
    }
  }
}

.data-grid-news {
  margin-bottom: 20px;
  border: 1px solid #ddd;
  border-top: 7px solid @complement-color-lighter;

  .container-loading {
    height: 160px!important;
    &:before {
      margin-top: 30px;
    }
  }

  table {
    border-collapse: collapse;
    width: 100%;
    background-color: #fff;
    font-size: 14px;
    padding-right: 15px;
    padding-left: 15px;

    caption {
      padding: 10px 0;
      font-size: 16px;
      color: #444;
      font-weight: bold;
      font-family: Asap, sans-serif;
      padding-left: 15px;
      h2 {
        margin: 0;
        padding-top: 5px;
        padding-bottom: 2px;
      }
    }

    td {
      border-top: 1px solid @brand-grey;
      text-align: left;
      height: 40px !important;
      padding-top: 0px !important;
      padding-left: 15px;
      padding-right: 15px;
      @media (max-width: @screen-md) {
        line-height: 25px !important;
        font-size: 14px;
        &.date {
          width: 85px;
        }
      }
    }

    .table-more-toggle {
      a {
        background-color: #fff;
        &:hover {
          background-color: @gray-lightest;
        }
        &:focus {
          background-color: #fff;
          &:hover {
            background-color: @gray-lightest;
          }
        }
      }
    }
  }

  &.clickable-rows {
    table tbody tr:hover {
      cursor: pointer;
      th,
      td {
        background-color: @gray-lightest;
        cursor: pointer;
        a {
          text-decoration: underline;
          color: @link-hover-color;
        }
      }
    }
  }
  .latest-news {
    font-weight: @badge-font-weight;
    i {
      position: relative;
      right: 7px;
      color: #ff6d1a;
    }
  }
}


.data-grid-tld {
  margin-bottom: 40px;
  border: 1px solid #d4d4d4;
  border-top: 4px solid @brand-grey;

  table {
    border-collapse: collapse;
    width: 100%;
    background-color: #fff;
    font-size: 12px;

    caption {
      padding: 10px 0;
      font-size: 16px;
      color: #444;
      font-weight: bold;
      text-align: center;
      h2 {
        padding-top: 2px;
        margin: 0;
      }
    }

    td {
      width: 50%;
      border-top: 1px solid @brand-grey;
      border-bottom: 1px solid @brand-grey;
      border-right: none;
      border-left: none;
      padding:16px 0px 16px 0px;
      font-size: 1.2em;
      font-weight: 400;


      &:nth-child(even) {
        text-align: left;
        padding-left: 10px;
      }
      &:nth-child(odd) {
        text-align: right;
        padding-right: 10px;
      }
    }

    .table-more-toggle {
      a {
        background-color: #fff;
        &:hover {
          background-color: @gray-lightest;
        }
        &:focus {
          background-color: #fff;
          &:hover {
            background-color: @gray-lightest;
          }
        }
      }
    }
  }

  &.clickable-rows {
    table tbody tr:hover {
      th,
      td {
        background-color: @state-info-border;
        cursor: pointer;
        a {
          text-decoration: underline;
          color: @link-hover-color;
        }
      }
    }
  }
}

.data-text-box {
  margin-bottom: 40px;
  h2 {
    padding-bottom: 12px;
  }
  li {
    border-right: none;
    border-left: none;
    padding:16px 0 16px 6px;
    font-size: 1em;
    font-weight: 400;
  }
  i {
    padding-left: 6px;
    padding-right: 12px;
    color: @complement-color-light;
  }
}