.animate-on-scroll(@property, @initialValue, @finalValue, @animateProperies: @property) {
  @{property}: @finalValue;
  @media (min-width: @screen-sm) {
    .transition(@animateProperies .2s);
    @{property}: @finalValue;
    .not-compact& {
      @{property}: @initialValue;
    }
  }
}
