.list-row {
  .clearfix;
  .make-row();
  margin: 0;
  clear: both;
  &:last-of-type {
    margin-bottom: @dub-unit;
  }
  &[class*=col-] {
    clear: none;
    &:last-of-type{
      margin-bottom: 0;
    }
  }
  dt, .list-label {
    #domain_contacts &, & {
      .make-sm-column(5);
    }
    &.short {.make-sm-column(3);
      ~ dd, ~.list-data {.make-sm-column(9); .list-data-setting;}
    }
    &.m-short {.make-sm-column(4);
      ~ dd, ~.list-data {.make-sm-column(8); .list-data-setting;}
    }
    &.medium {.make-sm-column(6);
      ~ dd, ~.list-data {.make-sm-column(6); .list-data-setting;}
    }
    &.wide {.make-sm-column(7);
      ~ dd, ~.list-data {.make-sm-column(5); .list-data-setting; min-width: 100px;}
    }
    &.list-label-lg {
      padding-top: @padding-large-horizontal;
    }
    position: relative;
    margin: 0;
    float: left;
    color: @text-color;
    text-align: right;
    min-width: 100px;
    min-height: 32px;
    font-weight: bold;
    padding: 6px 12px;
  }
  dd, .list-data {
    @media (min-width: @screen-sm) { 
      #domain_contacts &, & {
        .make-sm-column(7);
      }
    }
    .list-data-setting;
  }
  .list-data-setting {
    word-break: break-word;
    margin: 0;
    min-width: 140px;
    float: right;
    padding: 6px 12px 6px 24px;
    > .input-sm, > .input-container, > .btn-group, > .btn-sm, > .form-control-group {
      margin-top: -6px;
      margin-bottom: -6px;
    }
    @media (min-width: @screen-sm) { 
        .layout-compact & {
          padding: 0 12px;
        }
     }
  }
  
  .input-container {
    display: inline-block;
    min-height: 30px;
    vertical-align: middle;
    width: 100%;
    .input-group {
      width: 100%;
    }
  }
  > .input-container {
    padding-left: @grid-padding;
    padding-right: @grid-padding;
  }
  
  @media screen and (min-width: @screen-sm) { 
    dt, .list-label {
      .layout-compact & {
        padding: 0 12px;
      }
    }
  }
  
  @media screen and (max-width: @screen-xs-max) {
  
    dt, .list-label { 
      padding: 0;
      text-align: left;
      margin-top: 5px;
      .layout-compact & { 
        margin-top: 0;
        padding: 3px 12px;
      }
    }
    dd, .list-data {
      width: 100%;
      padding: 0;
      margin-top: -10px;
      > .input-sm, > .input-container, > .btn-group, > .btn-sm, > .form-control-group {
        margin-top: 10px;
        margin-bottom: 0;
        vertical-align: top;
      }
      .layout-compact & { 
        padding: 3px 12px;
      }
    }
  }
}

