// .tld-table {
// border: none;
//   thead {
//     tr {
//       th {
//         background-color: white;
//         border-bottom:2px solid @gray-light;
//       }
//     }
//   }

//   tbody {
//     tr {
//       &:nth-child(even) {
//         background-color: @gray-lightest;
//         border: none;
//       }
//       td {
//         padding: 12px 12px 0px 12px;
//         border: none;
//       }
//     }
//   }
// }
// .table-filter-group {
//   border: none;
//   padding-bottom: 0px;
//   background-color: #fff;
// }
// .table-header {
//   margin-top:28px;
//   margin-bottom: 20px;
//   h2 {
//     background-color:#fff;
//     text-align: center;
//     padding-bottom: 20px;
//     border-left: none;
//     border-right: none;
//   }
// }

// table-search {
//   display: inline-block;
//   button {

//   }
// }

.table-search-field {
  display: flex;
  position: relative;
  button {
    position: absolute;
    right: 2px;
    top: 2px;
    z-index: 1000;
    height: 34px;
    background-color:#fff;
    border: none;
    padding: 0 12px 0 0;
    &:hover {
      // background-color: #fafafa;
    }
    i {
      color: @gray;
    }
  }
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 26px;
  top: 6px;
  left: 8px;
  span {
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  }
}

.switch input {
  display: none;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.2s;
  transition: 0.2s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.2s;
  transition: 0.2s;
}

input:checked + .slider {
  background-color: #258520;
}

input:focus + .slider {
  box-shadow: 0 0 1px#258520;
}

input:checked + .slider:before {
  -webkit-transform: translateX(34px);
  -ms-transform: translateX(34px);
  transform: translateX(34px);
}

/* Rounded sliders */

.slider.round:before {
}

.vat-switch {
  label:first-child {
    position: relative;
    bottom: 6px;
    left: 0px;
  }
}
