.promo-slider-container {
  position: absolute;
  height: 25%;
  bottom: 0;
  left: 0;
  right: 0;
  border-bottom: 1px solid @gray-lighter;

  @media screen and (max-width: @screen-lg-max) {
    height: 20%;
  }
  @media screen and (max-width: @screen-md-max) {
    height: 15%;
  }
  @media screen and (max-width: @screen-sm-max) {
    position: relative;
    height: auto;
    .promo-tld-list {
      transform: none;
    }
	}
}

.promo-slider {
  position: relative;
  margin: 0 auto;
  padding-right: 0;
  padding-left: 0;
  background: #fff;
  height: 100%;
  font-size: 16px;
  @media (max-width: @screen-lg-max) {
    font-size: 14px;
  }
  @media (max-width: @screen-md-max) {
    font-size: 12px;
  }
  @media (max-width: @screen-xs-max) {
    font-size: 10px;
  }
}

.promo-slider-loading {
  .loading-sm;
  min-height: 0;
  &:before {
    margin-top: 22px;
  }
  .promo-tld {
    visibility: hidden;
  }
}

.promo-tld-list {
  position: relative;
  list-style: none;
  padding: 0;
  margin: 0;
  text-align: center;
  max-width: 100%;
  top: 50%;
  transform: translateY(-50%);
}

.promo-tld {
    display: inline-block;
    text-align: center;
    min-width: 6em;
    a  {
      display: block;
      text-decoration: none;
      color: #777;
      padding: 10px 20px;
      &:hover, &:focus {
        color: @complement-color-dark;
      }
      @media screen and (max-width: @screen-md-max) {
        padding: 8px 16px;
      }
    }
  }

.promo-tld-title {
  .heading-font;
  display: block;
  font-size: 3em;
  line-height: 1.2;
}

.promo-tld-price {
  color: @gray-dark;
}

.promo-tld-campaign-price {
  color: @brand-warning;
  font-weight: bold;
  @media (min-width: @screen-sm) {
    font-size: @font-size-small;
  }
  + .promo-tld-price {
    text-decoration: line-through;
  }
}

.promo-tld-more {
  .heading-font;
  display: block;
  font-size: 1em;
  position: relative;
  padding-left: 1.6em;
  height: 2.75em;
  line-height: 1.2;
  &:before {
    position: absolute;
    left: 0;
    top: .3em;
    .fa;
    content: @fa-var-chevron-right;
    font-size: 1.6em;
  }
}
