.panel-sidebar {
  .list-group {
    margin: -1px -1px 0;
  }
}

.panel.has-sidebar {
  .clearfix;
  .panel-sidebar {
    padding-left: 0;
    padding-right: 0;
    @media (max-width: @screen-xs-max) {
      padding: 0;
    }
  }
}

.container.no-sidebar {
  @media (min-width: @screen-lg-min) {
    width: @container-md;
  }
}

/*
.sidebar {
	nav.page {
		margin: 0 0 20px 0;
	}
	
	&.left .block {
		margin: 0 20px 20px 20px;
	}
	
	&.right .block {
		margin: 0 0 20px 20px;
	}
}
*/