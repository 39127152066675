body.page-domains-tld #content {
	.choose-tld {
		.form-item {
			float: right;
			margin: 0;
			padding-left: 10px;
			background: #efefef;
			border: 1px solid #ddd;
			
			label {
				display: inline;
				padding-right: 10px;
				font-size: 12px;
			}
			
			select {
				width: auto;
				margin: 5px;
			}
		}
	}
}

.logo-group.accreditations {
  li {
    max-width: 68px;
    max-height: 48px;
    img {
      padding: 8px;
      .transition(opacity .2s);
      &:hover {
        opacity: 0.8;
      }
    }
  }
}