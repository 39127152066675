//Password Strength
.password-strength {
  margin-top: 7px;
  max-width: 80%;
  position: relative;
  display: block;
  height: 5px;
  background-color: @gray-light;
  .password-strength-bar {
    display: block;
    height: 5px;
    background-color: @brand-danger;
  }
  .password-strength-string {
    font-style: italic;
    font-size: 11px;
    position: absolute;
    left: 100%;
    top: -5px;
    margin-left: 10px;
    min-width: 20%;
  }
}

//Icon group
.logo-group {
  font-size: 0;
  margin-bottom: 10px;
  > * {
    display: inline-block;
    max-width: 50px;
    width: 25%;
    height: auto;
    img {
      max-height: 100%;
      max-width: 100%;
    }
  }
}

//Feedback button
#us_report_button {
  display: none!important;
}
.report-bug-button {
  z-index: 2000;
  position: fixed;
  bottom: 20px;
  left: 20px;

  background: #be2739;
  color: #fff;
  font-size: 16px;
  line-height: 20px;
  padding: 10px 25px;
  font-weight: 400;
  &:hover, &:focus, &:active, &:visited {
    color: #fff;
    text-decoration: none;
  }

  @media (max-width: @screen-xs-max) {
    display: none;
  }
}

//Zopim
.zopim {
  margin-right: 200px!important;
  @media (max-width: @screen-xs-max) {
    display: none;
  }
}

//Status message
.status-message {
  .status-font-style;
}


//System message
.system-message {
  text-align: center;
  .system-message-icon {
    font-size: 52px;
    display: block;
  }
  .system-message-title {
    .status-font-style;
    padding-top: 5px;
    color: inherit;
    text-shadow: 0 0 2px rgba(255,255,255,1);
  }
}

//Logout Countdown
.logout-countdown {
  display: block;
  .type-size(32);
}

//Notice Component
.notice {
  opacity: 1;
  position: absolute;
  top: 50%;
  .translate(0, -50%);
  left: 0;
  width: 100%;
  z-index: 900;
  text-align: center;
}
.notice-icon {
  font-size: 62px;
  display: block;
  line-height: 1;
}
.notice-title {
  .status-font-style;
  padding-top: 5px;
  color: inherit;
  text-shadow: 0 0 2px rgba(255,255,255,1);
}
.notice-description {
  font-size: 12px;
  font-weight: 400;
}

/* Button Switch
-------------------------------------------------------------- */
//Displayed as table to make widths similar to the widest content. 
//Second child has negative top-margin to overlap, and the inactive button is made transparent in front of the other.

@btnSwitchHeight: 28px;
.btn-switch {
  &:extend(.btn-group all);
  height: @btnSwitchHeight;
  overflow: hidden;
}

.btn-switch-item {
  display: block;
  &:extend(.btn-group all);
  .switch-btn {
    display: block;
    position: relative;
    text-align: left;
    padding-left: 30px;
    padding-right: 5px;
    width: 100%;
    min-width: 70px;
    top: -@btnSwitchHeight;
    &:hover, &:focus {
      .switch-lever {
        background-color: darken(#fff,5%);
      }
    }
  }
  &:first-child .switch-btn {
    height: @btnSwitchHeight;
    top: 0;
    text-align: right;
    padding-right: 30px;
    padding-left: 5px;
  }
  .switch-lever {
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 20px;
    height: (unit(@btnSwitchHeight)-2px);
    z-index: 10;
    box-shadow: 4px 0 3px rgba(0, 0, 0, 0.3);
    border-radius: 3px;
    background-color: #fff;
    -webkit-perspective: 1000;
    -webkit-backface-visibility: hidden;
    //.transform(translate3d(0, 0, 0));
    //.transition(all .5s);
  }
  .switch-btn.active .switch-lever {
    //.transform(translate(-100%, 0));
    //left: 100%;
    right: 0;
    left: auto;
    box-shadow: -4px 0 3px rgba(0, 0, 0, 0.3);
  }

  .switch-btn:not(.active) {
    background-color: transparent;
    color: transparent;
    border-color: transparent;
    box-shadow: none;
    z-index: 10;
  }
}

//Checkout agreements
.confirm-agreements {
  .well;
  margin-bottom: 0;
  ul {
    margin-bottom: 0;
    padding-left: 20px;
    font-size: @font-size-small;
  }
  input.enhanced {
    & + i {
      vertical-align: text-top;
      &:before {
        font-size: 22px;
        width: 25px;
      }
    }
    &:checked + i:before {
      color: @brand-success;
    }
  }
  
}
.confirm-agreements-checkbox {
  background-color: #fff;
  display: block;
  padding: 10px 10px 7px;
  border: 1px solid @gray-light;
  margin: -10px -10px 10px;
}