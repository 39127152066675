@import "bootstrap/less/list-group.less";

.list-group {
  a.list-group-item {
    &.active {
      background-color: @brand-grey-custom;
      border-color: @list-group-border;
      color: @gray-darker;
    }
    &:before { 
      .far;
      position: relative;
    }
    &.active, &:hover, &:focus {
      &:before {
        content: @fa-var-angle-right;
        float: right;
        top: 3px;
      }
    }
  }
}
.list-group-header {
  h4 {
    &.list-group-item-heading {
      margin-top: 0;
      margin-bottom: 0;
      padding: 20px 15px;
      border-right: 1px solid #ddd;
    }
  }
  a.list-group-item {
    padding-top: 20px;
  }
  @media (max-width: @screen-xs-max) {
    display: table;
    width: 100%;
    a.list-group-item {
      display: table-cell;
      width: 100%;
      vertical-align: middle;
      padding-top: 11px;
      .list-group-item-heading {
        margin-bottom: 0;
      }
      &, &:hover, &:focus {
        &:before {
          content: @fa-var-angle-left;
          float: left;
          top: 1px;
          margin-right: 10px;
          background-color: #ebebed;
        }
      }
    }
    .list-group-nav-toggle {
      @media (max-width: @screen-xs-max) {
        display: none;
      }
      display: table-cell;
      border-top-width: 0;
      border: none;
      float: right;
      margin: 5px;
      &:hover, &:active, &:focus {
        background-color: #ebebed;
      }
    }
  }
}

.list-group-nav-toggle {
  .btn;
  .btn-default;
  .btn-lg;
  background-color: transparent;
  &:focus {
    outline: none !important;
  }
  @media (min-width: @screen-sm) {
    display: none;
  }

 
}

.list-group-nav-collapse {
  @media (min-width: @screen-sm) {
    display: block;
    height: auto !important;
  }
}

.list-group-item-text {
  line-height: @line-height-base;
  font-size: @font-size-small;
}