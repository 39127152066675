

.localization-menu {
  
  .dropdown-toggle {
    text-align: center;
    line-height: 11px;
    .transition(~'transform .2s, filter .2s, margin-top .2s');
    padding: 8.5px 5px 0;
    font-size: 10px;
    .compact & {
      .scale(0.9);
      margin-top: -5px;
    }
    .flag {
      display: block;
      margin-top: -5px;
      margin-bottom: -4px;
    }
    .currency {
      display: block;
      position: relative;
      z-index: 1;
      color: @brand-primary !important;
      font-weight: bold;
      margin-top: -2px;
      -webkit-font-smoothing: antialiased;
      padding: 3px 4.5px 3px;
      text-align: center;
      font-style: normal;
      text-decoration: none;
      background: url('../../../../images/currency-bg.png') no-repeat;
      background-size: contain;
    }
    .caret {
      margin-top: -5px;
      display: inline-block;
    }
    &:hover, &:focus {
      text-decoration: none;
      color: @brand-primary;
      -webkit-filter: brightness(0.7);
      filter: brightness(0.7);
      .translate(0, 2px);
      .currency {
        color: #fff;
      }
    }
  }
  .open .dropdown-toggle .caret {
    visibility: hidden;
  }
  
  .dropdown-menu {
    background-color: @brand-primary;
    &:before {
      content: '';
      display: block;
      position: absolute;
      bottom: 100%;
      right: 7px;
      border: 10px solid transparent;
      border-bottom: 10px solid @brand-primary;
    }
    li {
      position: relative;
      button {
        padding: 5px 15px 5px 30px; 
        border-radius: 0;
      }
      &.active {
        button {
          &:before {
            position: absolute;
            left: 8px;
            top: 10px;
            .fa;
            content: @fa-var-check;
            color: @brand-primary;
          }
        }
      }
    }
  }
}


/*
.localization-menu {
  position: relative;
  .dropdown-toggle {
    .site-header .site-header-top & {
      background-color: lighten(@gray-darker, 10%);
      color: @gray-light;
      padding-left: 10px;
      padding-right: 4px;
      border-right: 1px solid lighten(@gray-darker, 20%);
      &:hover, &:focus {
        background-color: lighten(@gray-darker, 20%);
        .caret {
          color: #fff;
        }
      }
      abbr {
        position: relative;
        top: 2px;
        display: inline-block;
      }
    }
  }
  
  .localization-menu-label {
    display: inline-block;
    min-width: 35px;
    text-align: center;
    .label {
      font-size: 10px;
    }
  }
}
*/
