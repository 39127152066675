.quick-menu {
	box-sizing: border-box;
	margin: 0px;
	padding: 0px;
	list-style-type: none;
	
	> ul {
		height: 100%;
		
		> li {
			float: left;
			height: 100%;
			
			>.button {
				height: 100%;
				padding: 0 10px;
				border-bottom: none;
				font-size: 11px;
				text-decoration: none;
				padding: 2px 10px;
				.button-inner {
					font-size: @font-size-small;
					color: @brand-warning;
				}
				.button-label {
          font-family: @headings-font-family;
        	font-weight: bold;
        	font-style: italic;
          -webkit-font-smoothing: antialiased;
        }

				
				&:hover {
  				background-color: @gray-lighter;
					.button-inner {
						color: @brand-warning;
					}
				}
				
				&.toggled {
					background-color: @gray-lightest;
					border: 1px solid #999;
					border-bottom: none;
				}
				
				&.locale-settings {
					.button-label {
						padding-left: 25px;
						background-repeat: no-repeat;
						background-position: 0 center;
					}
					
					&.country-us .button-label {
						background-image: url(/assets/images/flags/us.png);
					}
					
					&.country-se .button-label {
						background-image: url(/assets/images/flags/se.png);
					}
					
					&.country-no .button-label {
						background-image: url(/assets/images/flags/no.png);
					}
				}
			}
		}
	}
}