@keyframes slideIn {
    to {bottom: 0;}
}


.cookie-notice {
	display: block;
	position: fixed;
	bottom: -100px;
	width: 100%;
	z-index: 999;
	background: @gray-dark;
	border: none;
	border-top: 4px solid #111;
	color: @gray-lighter;
  font-size: @font-size-small;
  z-index: 2200;
  .animation(slideIn .5s ease-out 2s forwards);
	a {
  	color: lighten(@brand-info, 30%);
	}
	
	.container {
	  padding: 0;
  	position: relative;
  	text-align: center;
  	p {
    	margin: 0;
  	}
  	@media (max-width: @screen-xs-max) {
    	text-align: left;
    	padding: 5px @grid-padding;
	  }
	}
	.cookie-notice-dismiss {
		.button-variant(#fff; #111; #111);
		&:hover, &:focus {
  		background-color: @brand-success;
		}
    .btn-sm;
    margin-left: 10px;
		@media (max-width: @screen-xs-max) {
  		position: absolute;
  		margin: 0;
  		top: 0;
  		right: 0;
  		.translate(0,-100%);
		}
	}

}