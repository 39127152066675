.ui-keyboard {
  .panel;
  .panel-default;
  text-align: center;
  padding: 10px;
  white-space: nowrap;
  @media (max-width: @screen-xs-max) {
    padding-left: 0;
    padding-right: 0;
    margin-left: -(@grid-padding + 2);
    margin-right: -(@grid-padding + 2);
  }
  .btn {
    background-color: #fff;
    margin: 1px;
    @media (max-width: @screen-sm-max) {
      .btn-sm;
      
    }
    @media (max-width: @screen-xs-max) {
      &:not(.ui-keyboard-widekey) {
        width: 6%;
        padding-left: 0;
        padding-right: 0;
      }
    }
    
    &:hover, &:focus {
      background-color: #ddd;
    }
    &:focus {
      outline: none;
      box-shadow: 0 0 5px rgba(0,0,0,.3) inset;
    }
  }
 
}

.ui-keyboard-space {
  width: 170px;
}

/*
.widget.puny-code-converter {
	.form-item {
		float: left;
		box-sizing: border-box;
		width: 100%;
		margin-right: -200px;
		padding-right: 212px;
	}
		
	.button {
		float: left;
		width: 200px;
	}
	
	input.domainname {
		font-family: 'Arial Unicode MS', 'Bitstream Cyberbit', Code2000, 'Courier New';
	}
	
	.punycode {
		display: none;
	}
	
	.advanced-mode-toggle {
		margin-bottom: 0;
	}
	
	.search-button {
		display: none;
	}
	
	.advanced-mode {
		display: none;
		margin-top: 10px;
		
		.select-character-set {
			height: 24px;
			width: 200px;
		}
		
		.character-keys-table {
			border-spacing: 1px;
			border-collapse: separate;
			background: #ddd;

			td {
				height: 32px;
				padding: 0;
				background: #fafafa;
				vertical-align: top;
				
				button {
					width: 100%;
					height: 32px;
					
					span {
						font-family: 'Arial Unicode MS', 'Bitstream Cyberbit', Code2000, 'Courier New';
					}
				}
			}
		}
	}
}
*/