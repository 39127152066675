// This is a custom .less file for the odometer (http://github.hubspot.com/odometer/) library

.odometer.odometer-auto-theme {
  display: inline-block;
  position: relative;

  font-family: "Helvetica Neue", sans-serif; // Default theme
  line-height: 1.5em;

  //background-color: #222;
  background-color: @brand-primary;
  color: @brand-primary-lighter;
  padding: 2px;
  border-radius: 5px;

  .odometer-digit {
    &:first-child {
      border-radius: 3px 0 0 3px;
      margin-left: 0;
    }
    &:last-child {
      border-radius: 0 3px 3px 0;
      margin-right: 0;
    }

    display: inline-block;
    position: relative;

    //background-color: #333;
    background-color: lighten(@brand-primary, 5%);
    padding: 1px 4px;
    margin: 0 1px;

    .odometer-digit-spacer {
      display: inline-block;
      visibility: hidden;
    }

    .odometer-digit-inner {
      text-align: left;
      display: block;
      position: absolute;
      top: 1px;
      left: 4px;
      right: 4px;
      bottom: 1px;
      overflow: hidden;
    }

    .odometer-ribbon {
      display: block;
    }

    .odometer-ribbon-inner {
      display: block;
      -webkit-backface-visibility: hidden;
    }

    .odometer-value {
      display: block;
      -webkit-transform: translateZ(0);

      text-align: center; // Default theme

      &.odometer-last-value {
        position: absolute;
      }
    }
  }

  &.odometer-animating-up {
    .odometer-ribbon-inner {
      //-webkit-transition: -webkit-transform 2s;
      //-moz-transition: -moz-transform 2s;
      //-ms-transition: -ms-transform 2s;
      //-o-transition: -o-transform 2s;
      //transition: transform 2s;
      .transition(transform 2s);
    }

    &.odometer-animating .odometer-ribbon-inner {
      //-webkit-transform: translateY(-100%);
      //-moz-transform: translateY(-100%);
      //-ms-transform: translateY(-100%);
      //-o-transform: translateY(-100%);
      //transform: translateY(-100%);
      .translate(0, -100%);
    }
  }

  &.odometer-animating-down {
    .odometer-ribbon-inner {
      //-webkit-transform: translateY(-100%);
      //-moz-transform: translateY(-100%);
      //-ms-transform: translateY(-100%);
      //-o-transform: translateY(-100%);
      //transform: translateY(-100%);
      .translate(0, -100%);
    }

    &.odometer-animating .odometer-ribbon-inner {
      //-webkit-transition: -webkit-transform 2s;
      //-moz-transition: -moz-transform 2s;
      //-ms-transition: -ms-transform 2s;
      //-o-transition: -o-transform 2s;
      //transition: transform 2s;
      .transition(transform 2s);

      //-webkit-transform: translateY(0);
      //-moz-transform: translateY(0);
      //-ms-transform: translateY(0);
      //-o-transform: translateY(0);
      //transform: translateY(0);
      .translate(0, 0);
    }
  }
}
