// ----------------------------------------------------------------------------
//Glyph icons
// ----------------------------------------------------------------------------

@import '@fortawesome/fontawesome-pro-webfonts/less/fontawesome.less';
@import '@fortawesome/fontawesome-pro-webfonts/less/fa-solid.less';
@import '@fortawesome/fontawesome-pro-webfonts/less/fa-regular.less';
@import '@fortawesome/fontawesome-pro-webfonts/less/fa-light.less';
@import '@fortawesome/fontawesome-pro-webfonts/less/fa-brands.less';


//font-awesome
.glyph, [class*="glyph-"] {
  &:before { 
    .fa;
    vertical-align: 0;
  }
}

//fixed width
.glyph-fw {
  display: inline-block;
  text-align: center;
  width: 1.5em;
}

// Icon to the left of text
.icon-left {
  margin-right: 2px;
}
// Icon to the right of text
.icon-left {
  margin-left: 2px;
}

.glyph-error,
.glyph-unpaid,
.glyph-failed       { &:before {content: @fa-var-exclamation-circle;}}
.glyph-invalid      { &:before {content: @fa-var-exclamation;}}
.glyph-about,
.glyph-info         { &:before {content: @fa-var-info-circle;}}
.glyph-paid,
.glyph-success      { &:before {content: @fa-var-check-circle;}}
.glyph-warning      { &:before {content: @fa-var-exclamation-triangle;}}
.glyph-active       { &:before {content: @fa-var-play;}}
.glyph-special      { &:before {content: @fa-var-bolt;}}
.glyph-new          { &:before {content: @fa-var-plus-circle;}}
.glyph-transferred-away,
.glyph-expired,
.glyph-cancelled,
.glyph-cancel,
.glyph-abort        { &:before {content: @fa-var-times-circle;}}
.glyph-home         { &:before {content: @fa-var-home;}}
.glyph-faq,
.glyph-help         { &:before {content: @fa-var-question-circle;}}
.glyph-print        { &:before {content: @fa-var-print;}}
.glyph-send         { &:before {content: @fa-var-paper-plane;}}
.glyph-authcode     { &:before {content: @fa-var-barcode;}}
.glyph-campaign     { &:before {content: @fa-var-certificate;}}
.glyph-save,
.glyph-available    { &:before {content: @fa-var-check;}}
.glyph-unavailable  { &:before {content: @fa-var-times;}}
.glyph-reset        { &:before {content: @fa-var-undo;}}
.glyph-close        { &:before {content: @fa-var-times;}}
.glyph-edit         { &:before {content: @fa-var-pencil;}}
.glyph-inactive     { &:before {content: @fa-var-pause;}}
.glyph-favorite     { &:before {content: @fa-var-star;}}
.glyph-unfavorite   { &:before {.far; content: @fa-var-star;}}
.glyph-time,
.glyph-period       { &:before {.far; content: @fa-var-clock;}}
.glyph-view,
.glyph-show,
.glyph-whois        { &:before {content: @fa-var-eye;}}
.glyph-trustee      { &:before {content: @fa-var-street-view;}}
.glyph-nameserver   { &:before {content: @fa-var-server;}}
.glyph-date         { &:before {.far; content: @fa-var-calendar;}}
.glyph-refresh,
.glyph-restart      { &:before {content: @fa-var-sync-alt;}}
.glyph-action-required,
.glyph-alert        { &:before {content: @fa-var-exclamation-circle;}}
.glyph-add,
.glyph-plus         { &:before {content: @fa-var-plus;}}
.glyph-minus        { &:before {content: @fa-var-minus;}}
.glyph-ok,
.glyph-completed    { &:before {content: @fa-var-check-circle;}}
.glyph-warning      { &:before {content: @fa-var-exclamation-triangle;}}
.glyph-dashboard    { &:before {content: @fa-var-tachometer-alt;}}
.glyph-portfolio    { &:before {content: @fa-var-briefcase;}}
.glyph-settings     { &:before {content: @fa-var-cog;}}
.glyph-economy      { &:before {content: @fa-var-euro-sign;}}
.glyph-agreement,
.glyph-invoice,
.glyph-reciept      { &:before {.far; content: @fa-var-file-alt;}}
.glyph-currency,
.glyph-balance, 
.glyph-billing      { &:before {.far; content: @fa-var-money-bill-alt;}}
.glyph-account,
.glyph-user, 
.glyph-contact      { &:before {content: @fa-var-user;}}
.glyph-tech         { &:before {content: @fa-var-wrench;}}
.glyph-admin        { &:before {content: @fa-var-cog;}}
.glyph-owner        { &:before {content: @fa-var-male;}}
.glyph-users, 
.glyph-contacts     { &:before {content: @fa-var-users;}}
.glyph-support      { &:before {content: @fa-var-life-ring;}}
.glyph-contact-us   { &:before {content: @fa-var-comment;}}
.glyph-add-years    { &:before {content: @fa-var-plus-square;}}
.glyph-cart,
.glyph-add-to-cart  { &:before {content: @fa-var-shopping-cart;}}
.glyph-pay          { &:before {content: @fa-var-credit-card;}}
.glyph-tools        { &:before {content: @fa-var-wrench;}}
.glyph-signin       { &:before {content: @fa-var-sign-in;}}
.glyph-signout      { &:before {content: @fa-var-sign-out;}}
.glyph-fullscreen   { &:before {content: @fa-var-expand;}}
.glyph-export       { &:before {content: @fa-var-arrow-circle-down;}}
.glyph-download     { &:before {content: @fa-var-download;}}
.glyph-credit-card  { &:before {content: @fa-var-credit-card;}}
.glyph-pending      { &:before {content: @fa-var-spinner;}}
.glyph-compare,
.glyph-transfer     { &:before {content: @fa-var-exchange;}}
.glyph-transfer-lock,
.glyph-locked       { &:before {content: @fa-var-lock;}}
.glyph-unlocked     { &:before {content: @fa-var-unlock;}}
.glyph-check-all    { &:before {.far; content: @fa-var-check-square;}}
.glyph-language     { &:before {content: @fa-var-language;}}
.glyph-country,
.glyph-domain,
.glyph-domains      { &:before {content: @fa-var-globe;}}
.glyph-my-domains   { &:before {content: @fa-var-folder-open;}}
.glyph-cancel-domain,
.glyph-delete       { &:before {.far; content: @fa-var-trash-alt;}}
.glyph-external     { &:before {content: @fa-var-external-link;}}
.glyph-wizard       { &:before {content: @fa-var-magic;}}
.glyph-history      { &:before {content: @fa-var-history;}}
.glyph-first        { &:before {content: @fa-var-angle-double-left;}}
.glyph-previous,
.glyph-back         { &:before {content: @fa-var-angle-left;}}
.glyph-next         { &:before {content: @fa-var-angle-right;}}
.glyph-last         { &:before {content: @fa-var-angle-double-right;}}
.glyph-search       { &:before {content: @fa-var-search;}}
.glyph-copy         { &:before {.far; content: @fa-var-copy;}}
.glyph-clear        { &:before {content: @fa-var-eraser;}}
.glyph-expand       { &:before {.far; content: @fa-var-plus-square;}}
.glyph-collapse     { &:before {.far; content: @fa-var-minus-square;}}
.glyph-expanded-list  { &:before {content: @fa-var-th-list;}}
.glyph-collapsed-list { &:before {content: @fa-var-align-justify;}}
.glyph-arrow-down     { &:before {content: @fa-var-arrow-down;}}
.glyph-create-new     { &:before {.far; content: @fa-var-file;}}
.glyph-renew               { &:before {content: @fa-var-sync-alt;}}
.glyph-whois-protection    { &:before {content: @fa-var-eye-slash;}}
.glyph-label               { &:before {content: @fa-var-tag;}}
.glyph-labels              { &:before {content: @fa-var-tags;}}
.glyph-change-dns          { &:before {content: @fa-var-link;}}

.glyph-spinner             { &:before {.fas; .fa-spin; content: @fa-var-spinner;}}
.glyph-spinner-fast        { &:before {.fas; .fa-pulse; content: @fa-var-spinner;}}

//Stacked
.glyph-domains-advanced {
  position: relative;
  margin-right: 2px;
  &:before {
    content: @fa-var-globe;
  }
  &:after {
    .fa;
    content: @fa-var-bolt;
      font-size: 87.5%;
    position: absolute;
    right: -5px;
    top: -2px;
    background-color: #fff;
    border-radius: 100%;
    padding: 2px;
    .btn:hover &, .btn:focus & {
      background-color: #E6E6E6;
    }
  }
}

//icon toggles
.glyph-expand-collapse-list {
  &:before                { content: @fa-var-align-justify; }
  .collapsed &            { &:before {content: @fa-var-th-list;}}
}
.glyph-expand-collapse {
  &:before                {content: @fa-var-minus;}
  .collapsed &            { &:before {content: @fa-var-plus;}}
}
.glyph-expand-collapse-chevron {
  &:before                {content: @fa-var-chevron-up;}
  .collapsed &            { &:before {content: @fa-var-chevron-down;}}
}
.glyph-expand-collapse-chevron-reversed {
  &:before                {content: @fa-var-chevron-down;}
  .collapsed &            { &:before {content: @fa-var-chevron-up;}}
}
.glyph-favorite-toggle {
  .far;
  padding: 5px;
  &:hover, &:focus        { .scale(1.1); color: #000;}
  &:before                { content: @fa-var-star !important;}
  &.active, 
  .enhanced:checked + &   { &:before {content: @fa-var-star !important; color: #000;} }
  &.active                { &:hover:before {color: @text-color;} }
}
.glyph-checkbox {
  .far;
  margin-left: -5px;
  margin-right: 5px;
  &:before                { content: @fa-var-square; }
  .active &               { &:before {content: @fa-var-check-square; }}
  .semi-checked &         { &:before {content: @fa-var-minus-square;}}
}
//icon loading
.loadable-button.loading [class*="glyph-"],
.loadable-button.loading .glyph,
.loading .glyph-loadready,
.loading .glyph-refresh {
  .glyph-spinner-fast;
}
